import React from 'react'
import Modal from 'react-bootstrap/Modal'
import Spinner from 'react-bootstrap/Spinner'

import AuthManager from '../../../../utils/AuthManager'
import Backend from '../../../../utils/Backend'
import Email from '../../../../utils/Email'

import moment from 'moment'

export default class UserModal extends React.Component {
  constructor(props){
    super(props)

    this.state = this._getNextProps(props)
  }

  componentDidMount(){
    this._loadUserData()
  }

  componentWillReceiveProps(nextProps){

    let props = this._getNextProps(nextProps)

    this.setState(props, () => {
      this._loadUserData()
    })
  }

  _getNextProps(props){
    let addUserMode = props.user ? false : true

    return {
      addUserMode,
      show: props.show,
      user: props.user,
      data: {
        role: 1
      },
      isLoading: false,
      isLoading: addUserMode ? false : true,
      title: addUserMode ? 'Add User' : props.user.name,
      buttonTitle: addUserMode ? "Save" : "Update",
      error: null,
    }
  }

  _loadUserData(){
    let { addUserMode, user } = this.state

    if(addUserMode){
      return
    }

    Backend.getUserDetails(user)
    .then(userDetails => {

      let { roles } = userDetails

      let role = roles[0]
      let isAdmin = role.id == 2

      let data = {
        isAdmin,
        name: userDetails.name,
        email: userDetails.email,
        role: role.id,
      }
      this.setState({ data, isAdmin, isLoading: false })
    })
  }

  _handleButtonPressed(){
    let { addUserMode } = this.state

    if(addUserMode){
      this._handleAddUser()
    }
    else{
      this._handleUpdateUser()
    }
  }

  _handleAddUser(){
    if(!this._isFormValidAddUser()){
      return
    }

    let { data } = this.state
    data.created_at = moment().format('YYYY-MM-DD')
    data.org = AuthManager.dealership.code

    this.setState({ isLoading: true })
    Backend.createUser(data)
    .then(response => {
      this.props.onUserCreated()
    })
    .catch(error => {
      this.setState({
        isLoading: false,
        error: error.message
      })
    })
  }

  _handleUpdateUser(){
    if(!this._isFormValidUpdateUser()){
      return
    }

    let { data, user } = this.state

    this.setState({ isLoading: true })
    Backend.updateUser(user, data)
    .then(updatedUser => {
      this.props.onUserUpdated()
    })
    .catch(error => {
      this.setState({
        isLoading: false,
        error: error.message
      })
    })
  }

  _onSwitchRole(isAdmin){
    let data = { ...this.state.data }

    data.isAdmin = isAdmin
    data.role = isAdmin ? 2 : 1

    this.setState({ data })
  }

  _updateData(key, value){
    let data = { ...this.state.data }
    data[key] = value
    this.setState({ data, error: null })
  }

  _isFormValidAddUser(){
    let {
      data:{
        name,
        email,
        password,
        confirmPassword
      }
    } = this.state

    let isValid = true
    let error = null

    if(!name){
      error = "Invalid Name"
      isValid = false
    }
    else if(!Email.isValid(email)){
      error = "Invalid Email"
      isValid = false
    }
    else if(password == null || password.length < 8){
      error = "Password must have at least 8 characters"
      isValid = false
    }
    else if(password !== confirmPassword){
      error = "Passwords don't match"
      isValid = false
    }

    this.setState({ error })

    return isValid
  }

  _isFormValidUpdateUser(){
    let {
      data:{
        name,
        email,
        password,
        confirmPassword
      }
    } = this.state

    let isValid = true
    let error = null

    if(!name){
      error = "Invalid Name"
      isValid = false
    }
    else if(!Email.isValid(email)){
      error = "Invalid Email"
      isValid = false
    }
    else if(password || confirmPassword){
      if(password == null || password.length < 8){
        error = "Password must have at least 8 characters"
        isValid = false
      }
      else if(password != confirmPassword){
        error = "Passwords don't match"
        isValid = false
      }
    }

    this.setState({ error })

    return isValid
  }

  _renderContent(){

    let {
      isLoading,
      data,
      error
     } = this.state

    if(isLoading){
      return (
        <div style={{
          textAlign: 'center',
          paddingTop: 120,
          paddingBottom: 120
        }}>
          <Spinner animation="border" />
        </div>
      )
    }

    return (
      <div>
        <div className="form-group col-md-12">
          <p style={{color: 'red'}}>{ error }</p>
        </div>
        <div className="row">
          <div className="form-group col-md-12">
            <input
              className="form-control"
              type="text"
              placeholder="Name"
              value={data.name}
              onChange={event => this._updateData("name", event.target.value) }/>
          </div>
          <div className="form-group col-md-12">
            <input
              className="form-control"
              type="text"
              placeholder="Email"
              value={data.email}
              onChange={event => this._updateData("email", event.target.value) }/>
          </div>
          <div className="form-group col-md-12">
            <input
              className="form-control"
              type="password"
              placeholder="Password"
              value={data.password}
              onChange={event => this._updateData("password", event.target.value) }/>
          </div>
          <div className="form-group col-md-12">
            <input
              className="form-control"
              type="password"
              placeholder="Confirm Password"
              value={data.confirmPassword}
              onChange={event => this._updateData("confirmPassword", event.target.value) }/>
          </div>
          <div className="form-group row mx-0">
              <label class="col-5 col-form-label">Admin</label>
								<div class="col-7">
									<span class="kt-switch kt-switch--outline kt-switch--icon">
										<label>
											<input
                        type="checkbox"
                        class="check-all"
                        checked={data.isAdmin}
                        onChange={event => this._onSwitchRole(!data.isAdmin)}
                      />
											<span></span>
										</label>
									</span>
								</div>
          </div>
        </div>
      </div>
    )
  }

  _renderButtons(){
    let {
      buttonTitle,
      isLoading
    } = this.state

    if(isLoading){
      return null
    }

    return (
      <Modal.Footer>
          <button
            type="button"
            className="btn btn-secondary"
            onClick={() => this.props.onHide()}>Cancel</button>
          <button
            type="button"
            className="btn btn-primary"
            onClick={() => this._handleButtonPressed()}>{ buttonTitle }</button>
      </Modal.Footer>
    )
  }

  render() {
    let {
      show,
      title,
     } = this.state
    return (
      <Modal
        show={show}
        onHide={() => this.props.onHide()}
      >
        <Modal.Header closeButton>
          <Modal.Title>{ title }</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="kt-portlet kt-portlet--mobile">
              { this._renderContent() }
    			</div>
        </Modal.Body>

        { this._renderButtons() }
      </Modal>
    )
  }
}
