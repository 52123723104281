import React from "react"

import SelectOrdersTable from './components/tables/SelectOrdersTable'

import AddServicesModal from './components/modals/AddServicesModal'

import SearchBar from './components/SearchBar'

import Backend from '../../utils/Backend'

export default class AddServices extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      searchTerm: '',
      isLoading: false,
      selectedOrders: [],
      showAddServicesModal: false
    }

    this.selectOrdersTable = React.createRef();
  }

  _onAddServicesPressed(order){
    this.setState({ showAddServicesModal: true })
  }

  _addServices(orders, services){
    this._closeModals()

    this.props.history.push({
      pathname: '/dealer/checkout',
      state: {
        services,
        orders: orders,
      }
    })
  }

  _closeModals(){
    this.setState({
      showAddServicesModal: false,
    })
  }

  _renderAddServicesButton(){
    let { selectedOrders } = this.state
    if(selectedOrders.length == 0){
      return null
    }

    return (
      <div className="kt-form kt-form--label-align-right kt-margin-t-20">
        <div className="row align-items-center">
          <div className="col-xl-12">
            <div className="kt-form__group kt-form__group--inline">
              <div className="kt-form__label kt-form__label-no-wrap">
                <label className="kt-font-bold kt-font-danger-">Selected
                  <span id="kt_datatable_selected_number"> { selectedOrders.length }</span> records:</label>
              </div>
              <div className="kt-form__control">
                <div className="btn-toolbar">
                  <div className="dropdown">
                    <button
                      type="button"
                      className="btn btn-brand btn-sm"
                      onClick={() => this._onAddServicesPressed()}
                    >
                      Add Services to Selected
                    </button>
                  </div>
                  &nbsp;&nbsp;&nbsp;
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }


  render() {
    return (
      <div>
        <div className="row">
          <div className="col-lg-12">
            <div className="kt-portlet kt-portlet--mobile">
              <div className="kt-portlet__head kt-portlet__head--lg">
                <div className="kt-portlet__head-label">
                  <span className="kt-portlet__head-icon">
                    <i className="kt-font-brand flaticon2-delivery-truck"></i>
                  </span>
                  <h3 className="kt-portlet__head-title">
                    Choose which vehicles to add services to
                  </h3>
                </div>
              </div>
              <div className="kt-portlet__body">

                <SearchBar
                   onSearchTermChanged={searchTerm => this.setState({ searchTerm })}
                   showSelectStatusFilters={false}/>

                { this._renderAddServicesButton()}

                {/* end: Selected Rows Group Action Form  */}
              </div>
              <div className="kt-portlet__body kt-portlet__body--fit">

              {/* begin: Datatable  */}
              <SelectOrdersTable
                ref={this.selectOrdersTable}
                endpoint={window.env.REACT_APP_API_FEED+"?addServices=true"}
                disabled={this.state.isLoading}
                searchTerm={this.state.searchTerm}
                onSelectedOrdersUpdated={selectedOrders => this.setState({ selectedOrders })}/>
              {/* end: Datatable  */}

              <AddServicesModal
                show={this.state.showAddServicesModal}
                onHide={() => this._closeModals()}
                orders={this.state.selectedOrders}
                onAddServicesPressed={services => this._addServices(this.state.selectedOrders, services)}
                />

              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
