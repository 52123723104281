import FetchHelper from './FetchHelper'
import AuthManager from './AuthManager'
import Orders from './Orders'
import DataCollection from './DataCollection'

export default class Backend {
  static getSettings() {
    return FetchHelper.get(window.env.REACT_APP_API_SETTINGS)
  }

  static updateSettings(data) {
    return FetchHelper.post(window.env.REACT_APP_API_SETTINGS, data)
  }

  static getCurrentUser() {
    return FetchHelper.get(window.env.REACT_APP_API_USER_PROFILE)
  }

  static getVinDetails(order) {
    return FetchHelper.get(
      window.env.REACT_APP_API_VINDETAILS + '?vin=' + order.vin
    )
  }

  static getAddOns(order) {
    return FetchHelper.get(
      window.env.REACT_APP_API_ADD_ONS + '?vin=' + order.vin
    )
  }

  static track(order) {
    DataCollection.track(window.Firebase_Event.TRACK)
    return FetchHelper.get(
      window.env.REACT_APP_API_TRACK +
        '?tour=' +
        order.tour +
        '&vin=' +
        order.vin
    )
  }

  static getUserDetails(user) {
    return FetchHelper.get(window.env.REACT_APP_API_STAFF + '/' + user.id)
  }

  static updateUser(user, data) {
    data.id = user.id
    DataCollection.track(window.Firebase_Event.USER_UPDATED)
    return FetchHelper.post(window.env.REACT_APP_API_USER_UPDATE_PROFILE, data)
  }

  static createUser(data) {
    DataCollection.track(window.Firebase_Event.USER_CREATED)
    return FetchHelper.post(window.env.REACT_APP_API_USER_CREATE, data)
  }

  static queryCars(orders, question) {
    let vins = orders.map((order) => {
      return order.vin
    })

    let data = {
      vins,
      question,
    }

    DataCollection.track(window.Firebase_Event.QUERY_CARS, {
      vins,
    })

    return FetchHelper.post(window.env.REACT_APP_API_QUERY_CARS, data)
  }

  static callOff(orders) {
    let vins = orders.map((order) => {
      return order.vin
    })

    let data = {
      vins,
    }

    DataCollection.track(window.Firebase_Event.CALL_OFF, {
      vins,
    })

    return FetchHelper.post(window.env.REACT_APP_API_CALL_OFF, data)
  }

  static getFeed() {
    return FetchHelper.get(window.env.REACT_APP_API_FEED)
  }

  static getFeedStatusFilters() {
    return FetchHelper.get(window.env.REACT_APP_API_FEED_STATUS_FILTERS)
  }

  static getServices(orders, serviceBeforeCallOff) {
    let vins = Orders.getVins(orders)

    let data = {
      vins,
      serviceBeforeCallOff,
    }
    return FetchHelper.post(window.env.REACT_APP_API_SERVICES, data)
  }

  static checkout(orders, services, callOff) {
    let vins = Orders.getVins(orders)

    let serviceCodes = services.map((service) => {
      return service.code
    })

    let data = orders.map((order) => {
      return {
        callOff,
        vin: order.vin,
        service: serviceCodes,
        location: order.currentLocationCode,
      }
    })

    DataCollection.track(window.Firebase_Event.CHECKOUT, {
      vins,
      serviceCodes,
    })

    return FetchHelper.post(window.env.REACT_APP_API_CHECKOUT, data)
  }

  static getDealerships() {
    return FetchHelper.get(window.env.REACT_APP_API_USER_DEALERSHIP)
  }

  static getStaff() {
    let dealership = AuthManager.dealership

    return FetchHelper.get(
      window.env.REACT_APP_API_STAFF + '?garageId=' + dealership.code
    )
  }

  static getTrackingDetails(token) {
    return FetchHelper.get(
      window.env.REACT_APP_API_BASE + '/tourGPSTrackingLink/' + token
    )
  }

  static getShareUrl(order) {
    DataCollection.track(window.Firebase_Event.SHARE_TRACKING_LINK, {
      vin: order.vin,
    })

    return FetchHelper.get(
      window.env.REACT_APP_API_SHARE_URL + '?vin=' + order.vin
    ).then((response) => {
      return response.response
    })
  }

  static getOpenSlots() {
    return FetchHelper.get(window.env.REACT_APP_API_OPEN_SLOTS)
  }

  static selfCollect(orders, date) {
    let data = {
      bookingTime: date,
      dealerName: AuthManager.dealership.name,
      vins: Orders.getVins(orders),
    }
    console.log('data', data)
    DataCollection.track(window.Firebase_Event.SELF_COLLECT, {
      vins: Orders.getVins(orders),
    })
    return FetchHelper.post(window.env.REACT_APP_API_SELF_COLLECT, data)
  }

  static addExcludedDealerGarage(garage){
    let data = [{
      garage
    }]
    return FetchHelper.post(window.env.REACT_APP_API_DELAY_EXCEPTIONS, data)
  }

  static deleteExcludedDealerGarages(garages){
    let data = [...garages]
    return FetchHelper.delete(window.env.REACT_APP_API_DELAY_EXCEPTIONS, data)
  }

  static saveCallOffLimit(data){
    return FetchHelper.post(window.env.REACT_APP_API_CALL_OFF_LIMITS, [data])
  }

  static updateCallOffLimit(data){
    return FetchHelper.post(`${window.env.REACT_APP_API_CALL_OFF_LIMITS}`, [data])
  }

  static deleteCallOffLimit(callOffLimit){
    return FetchHelper.delete(`${window.env.REACT_APP_API_CALL_OFF_LIMITS}/${callOffLimit.id}`)
  }
}
