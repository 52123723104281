import React from 'react'

import ReactTable from 'react-table'
import 'react-table/react-table.css'

import selectTableHOC from "react-table/lib/hoc/selectTable";

import FetchHelper from '../../../../utils/FetchHelper'
import Orders from '../../../../utils/Orders'

const SelectTable = selectTableHOC(ReactTable);

export default class SelectOrdersTable extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      searchTerm: props.searchTerm,
      orders: [],
      filteredOrders: [],
      selectedOrders: [],
      isLoading: true,
      endpoint: props.endpoint,
      selectAll: false,

    }

    this.successModal = React.createRef();

  }

  componentDidMount(){
    this._load()
  }

  componentWillReceiveProps(nextProps){
    this.setState(nextProps, () => {
      this._search(this.state.searchTerm)
    })
  }

  _search(searchTerm){
    let orders = this.state.orders

    let filteredOrders = Orders.search(searchTerm, [], orders)
    this.setState({ filteredOrders })
  }

  refresh(){
    this.setState({
      orders: [],
      filteredOrders: [],
      selectedOrders: [],
      isLoading: true,
    }, () => {
      this._load()
    })
  }

  _load(){
    FetchHelper.get(this.state.endpoint)
    .then(orders => {
      this.setState({
        orders,
        filteredOrders: orders,
        isLoading: false,
      })
    })
    .catch(error => {
      alert(error.message)
      this.setState({ isLoading: false })
    })
  }

  _getColumns(){
    if(this.props.columns){
      return this.props.columns
    }
    return [
      {
        Header: 'VIN',
        accessor: 'vin'
      },
      {
        Header: 'Chassis / Comm',
        accessor: 'chassis'
      },
      {
        id: 'CarType',
        Header:  'Car Type',
        accessor: order => order.make + " " + order.modelCode
      },
      {
        id: "Status",
        Header: "Status",
        accessor: order => Orders.getFormattedStatus(order),
        getProps: (state, rowInfo, column) => {
          return {
            style: {
              color: rowInfo ? rowInfo.original.hexCode : 'black'
            }
          }
        }
      },
    ]
  }

  _isSelected(vin){
    let index =  this.state.selectedOrders.findIndex(order => order.vin === vin);
    return index > -1
  }

  _toggleAll(){
    let selectedOrders = []
    if(this.state.selectedOrders.length < this.state.orders.length){
      selectedOrders = this.state.filteredOrders
    }

    this.setState({ selectedOrders })
    this.props.onSelectedOrdersUpdated(selectedOrders)
  }

  _toggleSelection(vin){
    let { orders } = this.state
    let selectedOrders = [ ...this.state.selectedOrders ]
    let index = selectedOrders.findIndex(order => order.vin === vin);

    let isSelected = index > -1

    if(isSelected){
      selectedOrders.splice(index, 1)
    }
    else{
      let orderIndex = orders.findIndex(order => order.vin === vin);
      selectedOrders.push(orders[orderIndex])
    }

    this.setState({ selectedOrders })
    this.props.onSelectedOrdersUpdated(selectedOrders)
  }

  _getSelectAll(){
    return this.state.selectedOrders.length == this.state.orders.length
  }

  render(){
    const columns = this._getColumns()

    return (
      <div>
        <SelectTable
          keyField={this.props.keyField}
          data={this.state.filteredOrders}
          columns={columns}
          loading={this.state.isLoading}
          isSelected={vin => this._isSelected(vin)}
          selectAll={this._getSelectAll()}
          toggleAll={() => this._toggleAll()}
          toggleSelection={(key, shift, row) => this._toggleSelection(row.vin)}
          selectType="checkbox"
          getTdProps={(state, rowInfo, column, instance) => {
            return {
              style: {
                color: 'grey',
                paddingLeft: 10,
              }
            }
          }}
          getTrProps={(state, rowInfo, column, instance) => {
            return {
              style: {
                paddingTop: 10,
                paddingBottom: 10,
              }
            }
          }}
          getTheadProps={(state, rowInfo, column, instance) => {
            return {
              style: {
                boxShadow: '0 1px 1px 0 rgba(0,0,0,0.1)',
                paddingTop: 10,
                paddingBottom: 10,
                textAlign : 'left',
              }
            }
          }}
          getTheadThProps={(state, rowInfo, column, instance) => {
              return {
                style: {
                  textAlign : 'left',
                  paddingLeft: 10
                }
              }
          }}
        />
      </div>
    )
  }
}

SelectOrdersTable.defaultProps = {
  keyField: "vin",
}
