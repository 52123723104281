import FetchHelper from './FetchHelper'
import Backend from './Backend'
import Base64 from './Base64'

import DataCollection from './DataCollection'

export default class AuthManager {
  static isLoggedIn = false
  static token = null
  static currentUser = null
  static currentUserIsAdmin = false

  static superUserSelectedDealerCodes = []

  static isAuthenticated() {
    return AuthManager.isLoggedIn
  }

  static isSuperUser(){
    if(AuthManager.currentUser == null){
      return false
    }
    return AuthManager.currentUser.roles[0].roleName === "SUPER_USER"
  }

  static getToken() {
    return AuthManager.token
  }

  static getCurrentUser() {
    return FetchHelper.get(window.env.REACT_APP_API_USER_PROFILE).then(user => {
      AuthManager._setUser(user)
      return AuthManager.currentUser
    })
  }

  static updateUser(data) {
    return FetchHelper.post(
      window.env.REACT_APP_API_USER_UPDATE_PROFILE,
      data
    ).then(user => {
      AuthManager._setUser(user)
      return AuthManager.currentUser
    })
  }

  static _setUser(user) {
    AuthManager.currentUser = user
    AuthManager.currentUserIsAdmin = user.roles[0].id == 2
  }

  static getSuperUserSelectedDealerCodes(){
    let selectedDealerCodes = localStorage.getItem('selectedDealerCodes')
    if (selectedDealerCodes == null) {
      return []
    }
    return JSON.parse(selectedDealerCodes)
  }

  static setSuperUserSelectedDealerCodes(dealerCodes){
    localStorage.setItem('selectedDealerCodes', JSON.stringify(dealerCodes))
  }

  static login(email, password) {
    let data = {
      username: email,
      password,
      grant_type: 'password'
    }

    let formData = new FormData()
    for (var key in data) {
      formData.append(key, data[key])
    }

    let headers = AuthManager._getLoginHeaders()
    return FetchHelper.post(
      window.env.REACT_APP_API_LOGIN,
      formData,
      true,
      headers
    )
    .then(responseJson => {
      if (!responseJson.access_token) {
        throw AuthManager.getError(responseJson)
      }
      return AuthManager.handleSuccess(responseJson)
    })
    .then(() => {
      return AuthManager.getCurrentUser()
    })
    .then(() => {
      if(AuthManager.isSuperUser()){
        return null
      }

      DataCollection.setUser(AuthManager.currentUser)
      DataCollection.track(window.Firebase_Event.SIGN_IN)
      return Backend.getDealerships()
    })
    .then(dealerships => {
      if (AuthManager.dealership == null && !AuthManager.isSuperUser()) {
        console.log('7777', AuthManager.dealership)
        AuthManager.dealership = dealerships[0]
        DataCollection.setDealership(AuthManager.dealership)
      }
      return true
    })
    .catch(error => {
      throw AuthManager.getError(error)
    })
  }

  static silentLogin() {
    return AuthManager.getRefreshToken()
    .then(refreshToken => {
      if (!refreshToken) {
        throw new Error({ message: 'No active login' })
      }
      // try and refresh the token if we find it, if this fails
      // our token has expired and we wil lneed user to re login
      // manually
      const data = {
        refresh_token: refreshToken,
        grant_type: 'refresh_token'
      }

      let formData = new FormData()
      for (var key in data) {
        formData.append(key, data[key])
      }

      let headers = AuthManager._getLoginHeaders()
      return FetchHelper.post(
        window.env.REACT_APP_API_REFRESH_TOKEN,
        formData,
        true,
        headers
      )
    })
    .then(responseJson => {
      if (!responseJson.access_token) {
        throw AuthManager.getError(responseJson)
      }
      return AuthManager.handleSuccess(responseJson)
    })
    .then(() => {
      return AuthManager.getCurrentUser()
    })
    .then(() => {
      console.log("bbb", AuthManager.currentUser)
      if(AuthManager.isSuperUser()){
        return null
      }

      DataCollection.setUser(AuthManager.currentUser)
      DataCollection.track(window.Firebase_Event.SIGN_IN)
      return Backend.getDealerships()
    })
    .then(dealerships => {
      if (AuthManager.dealership == null && !AuthManager.isSuperUser()) {
        console.log('7777', AuthManager.dealership)
        AuthManager.dealership = dealerships[0]
        DataCollection.setDealership(AuthManager.dealership)
      }
      return true
    })
    .catch(error => {
      throw AuthManager.getError(error)
    })
  }

  static logout() {
    DataCollection.track(window.Firebase_Event.SIGN_OUT)
    return AuthManager.removeCredentials()
  }

  static requestResetPassword(email) {
    let formData = new FormData()
    formData.append('email', email)

    return FetchHelper.post(
      window.env.REACT_APP_API_USER_REQUEST_RESET_PASSWORD,
      formData,
      true
    )
  }

  static resetPassword(email, password, code) {
    let data = {
      email,
      password,
      code
    }

    return FetchHelper.post(
      window.env.REACT_APP_API_USER_RESET_PASSWORD,
      data
    )
  }

  static removeCredentials() {
    AuthManager.token = null
    AuthManager.refreshToken = null
    AuthManager.tokenType = null

    AuthManager.isLoggedIn = false
    AuthManager.currentUser = null

    return localStorage.clear()
  }

  static getError(error) {
    console.log('get error', error)
    var errorMessage = 'An unexpected error occurred'
    if (error.email) {
      errorMessage = error.email[0]
    } else if (error.message) {
      errorMessage = error.message
    } else if (error.error_description) {
      errorMessage = error.error_description
    } else if (error.detail) {
      errorMessage = error.detail
    }
    return { error: errorMessage, message: errorMessage }
  }

  static handleSuccess(responseJson) {
    // store the token
    AuthManager.token = responseJson.access_token
    AuthManager.refreshToken = responseJson.refresh_token
    AuthManager.tokenType = responseJson.token_type
    AuthManager.isLoggedIn = true

    let values = {
      token: AuthManager.token,
      refreshToken: AuthManager.refreshToken,
      tokenType: AuthManager.tokenType
    }

    return localStorage.setItem('credentials', JSON.stringify(values))
  }

  static getHeaders(contentType = 'application/json') {
    var headers = {}
    if (contentType === 'application/json') {
      headers = { 'Content-Type': contentType }
    }
    if (AuthManager.token) {
      headers['Authorization'] = AuthManager.tokenType + ' ' + AuthManager.token
    }

    if(AuthManager.isSuperUser()){
      headers['dealerCode'] = AuthManager.getSuperUserSelectedDealerCodes().join(',')
    }

    return headers
  }

  static _getLoginHeaders() {
    var headers = {}

    headers['Authorization'] =
      'Basic ' +
      Base64.encode(
        window.env.REACT_APP_AUTH_USERNAME +
          ':' +
          window.env.REACT_APP_AUTH_PASSWORD
      )

    return new Headers(headers)
  }

  static getCredentials() {
    return new Promise((resolve, reject) => {
      try {
        let credentialsString = localStorage.getItem('credentials')
        if (credentialsString == null) {
          reject({ message: 'Not logged in' })
        }
        let credentials = JSON.parse(credentialsString)
        resolve(credentials)
      } catch (error) {
        reject(error)
      }
    })
  }

  static getRefreshToken() {
    return AuthManager.getCredentials().then(credentials => {
      return credentials.refreshToken
    })
  }
}
