import React from "react"

import UsersTable from './components/tables/UsersTable'

import SuccessModal from './components/modals/SuccessModal'
import UserModal from './components/modals/UserModal'

export default class Users extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      showUserModal: false
    }

    this.successModal = React.createRef();
    this.usersTable = React.createRef();

  }

  _onEditPressed(user){
    this.setState({ showUserModal: true, selectedUser: user})
  }

  _onAddUserPressed(){
    this.setState({ showUserModal: true, selectedUser: null})
  }

  _closeModals(){
    this.setState({
      showUserModal: false,
      selectedUser: null,
    })
  }

  _onUserUpdated(title){
    this._closeModals()
    this.usersTable.current.refresh()
    this.successModal.current.show(title, "")
  }

  _renderModals(){
    let { selectedUser } = this.state

    return (
      <div>
        <UserModal
          show={this.state.showUserModal}
          onHide={() => this._closeModals()}
          user={this.state.selectedUser}
          onUserUpdated={() => this._onUserUpdated("User Updated")}
          onUserCreated={() => this._onUserUpdated("User Created")}
          />
      </div>
    )
  }

  render() {
    return (
      <div>
        <div className="kt-portlet kt-portlet--mobile">
          <div className="kt-portlet__head kt-portlet__head--lg">
            <div className="kt-portlet__head-label">
              <span className="kt-portlet__head-icon">
                <i className="kt-font-brand flaticon-car"></i>
              </span>
              <h3 className="kt-portlet__head-title">
                Your Users
              </h3>
            </div>
            <div className="kt-portlet__head-toolbar">
              <div className="kt-portlet__head-wrapper">
                &nbsp;
                <div className="dropdown dropdown-inline">
                  <button
                    type="button"
                    className="btn btn-brand btn-icon-sm"
                    onClick={() => this._onAddUserPressed()}>
                    <i className="flaticon2-plus"></i> New User
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="kt-portlet__body">
          </div>
          <div className="kt-portlet__body kt-portlet__body--fit">

            <UsersTable
              ref={this.usersTable}
              onEditPressed={user => this._onEditPressed(user)}/>
            { this._renderModals() }
            <SuccessModal ref={this.successModal}/>

          </div>
        </div>


      </div>
    )
  }
}
