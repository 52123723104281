import React from 'react'

import ReactTable from 'react-table'
import 'react-table/react-table.css'

import selectTableHOC from "react-table/lib/hoc/selectTable";

import Backend from '../../../../utils/Backend'
import Orders from '../../../../utils/Orders'

const SelectTable = selectTableHOC(ReactTable);

export default class AddServicesTable extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      orders: props.orders,
      services: [],
      filteredServices: [],
      selectedServices: [],
      isLoading: true,
      selectAll: false,
      serviceBeforeCallOff: props.serviceBeforeCallOff
    }
  }

  componentDidMount(){
    this._load()
  }

  componentWillReceiveProps(nextProps){
    let loadServices = this._getShouldLoadServices(nextProps)
    this.setState(nextProps, () => {
      if(loadServices){
        this._load()
      }
    })
  }

  _getShouldLoadServices(nextProps){
    return JSON.stringify(nextProps.orders) != JSON.stringify(this.state.orders)
  }

  _load(){
    this.setState({ isLoading: true })
    Backend.getServices(this.state.orders, this.state.serviceBeforeCallOff)
    .then(services => {
      this.setState({
        services,
        filteredServices: services,
        isLoading: false,
      })
    })
    .catch(error => {
      alert(error.message)
      this.setState({ isLoading: false })
    })
  }

  _getColumns(){
    return [
      {
        Header: 'Title',
        accessor: 'description',
      },
      {
        Header: 'Code',
        accessor: 'code'
      },
      {
        Header: 'Price',
        accessor: 'defaultPrice',
        maxWidth: 70
      },
    ]
  }

  _isSelected(code){
    let index =  this.state.selectedServices.findIndex(service => service.code === code);
    return index > -1
  }

  _toggleAll(){
    let selectedServices = []
    if(this.state.selectedServices.length < this.state.services.length){
      selectedServices = this.state.filteredServices
    }

    this.setState({ selectedServices })
    this.props.onSelectedServicesUpdated(selectedServices)
  }

  _toggleSelection(code){
    let { services } = this.state
    let selectedServices = [ ...this.state.selectedServices ]
    let index = selectedServices.findIndex(service => service.code === code);

    let isSelected = index > -1

    if(isSelected){
      selectedServices.splice(index, 1)
    }
    else{
      let serviceIndex = services.findIndex(service => service.code === code);
      selectedServices.push(services[serviceIndex])
    }

    this.setState({ selectedServices })
    this.props.onSelectedServicesUpdated(selectedServices)
  }

  _getSelectAll(){
    return this.state.selectedServices.length == this.state.services.length
  }

  render(){
    const columns = this._getColumns()

    return (
      <div>
        <SelectTable
          keyField="code"
          data={this.state.filteredServices}
          columns={columns}
          loading={this.state.isLoading}
          isSelected={code => this._isSelected(code)}
          selectAll={this._getSelectAll()}
          toggleAll={() => this._toggleAll()}
          toggleSelection={(key, shift, row) => this._toggleSelection(row.code)}
          selectType="checkbox"
          minRows={5}
          getTdProps={(state, rowInfo, column, instance) => {
            return {
              style: {
                color: 'grey',
                paddingLeft: 10,
              }
            }
          }}
          getTrProps={(state, rowInfo, column, instance) => {
            return {
              style: {
                paddingTop: 10,
                paddingBottom: 10,
              }
            }
          }}
          getTheadProps={(state, rowInfo, column, instance) => {
            return {
              style: {
                boxShadow: '0 1px 1px 0 rgba(0,0,0,0.1)',
                paddingTop: 10,
                paddingBottom: 10,
                textAlign : 'left',
              }
            }
          }}
          getTheadThProps={(state, rowInfo, column, instance) => {
              return {
                style: {
                  textAlign : 'left',
                  paddingLeft: 10
                }
              }
          }}
        />
      </div>
    )
  }
}

AddServicesTable.defaultProps = {
  serviceBeforeCallOff: true
}
