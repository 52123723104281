import React from "react"

import DealersTable from './components/tables/DealersTable'

import SearchBar from './components/SearchBar'

import AuthManager from '../../utils/AuthManager'

export default class Dealerships extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      searchTerm: '',
      isLoading: false,
      selectedDealers: [],
      showSelfCollectModal: false,
    }

    this.selectDealersTable = React.createRef();
  }


  _viewDealers(dealers){
    let dealerCodes = dealers.map(dealer => dealer.code)
    AuthManager.setSuperUserSelectedDealerCodes(dealerCodes)
    this.props.history.push("/dealer")
  }

  _renderViewDealersButton(){
    let { selectedDealers } = this.state
    if(selectedDealers.length == 0){
      return null
    }

    return (
      <div className="kt-form kt-form--label-align-right kt-margin-t-20">
        <div className="row align-items-center">
          <div className="col-xl-12">
            <div className="kt-form__group kt-form__group--inline">
              <div className="kt-form__label kt-form__label-no-wrap">
                <label className="kt-font-bold kt-font-danger-">View Dealers
                  <span id="kt_datatable_selected_number"> { selectedDealers.length }</span> records:</label>
              </div>
              <div className="kt-form__control">
                <div className="btn-toolbar">
                  <div className="dropdown">
                    <button
                      type="button"
                      className="btn btn-brand btn-sm"
                      onClick={() => this._viewDealers(selectedDealers)}
                    >
                      View Dealers
                    </button>
                  </div>
                  &nbsp;&nbsp;&nbsp;
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  render() {
    return (
      <div>
        <div className="row">
          <div className="col-lg-12">
            <div className="kt-portlet kt-portlet--mobile">
              <div className="kt-portlet__head kt-portlet__head--lg">
                <div className="kt-portlet__head-label">
                  <span className="kt-portlet__head-icon">
                    <i className="kt-font-brand flaticon-truck"></i>
                  </span>
                  <h3 className="kt-portlet__head-title">
                    Choose which dealers to view
                  </h3>
                </div>
              </div>
              <div className="kt-portlet__body">
                <SearchBar
                   onSearchTermChanged={searchTerm => this.setState({ searchTerm })}
                   showSelectStatusFilters={false}/>
                  {this._renderViewDealersButton() }
                </div>
                <div className="kt-portlet__body kt-portlet__body--fit">

                {/* begin: Datatable  */}
                <DealersTable
                  ref={this.selectDealersTable}
                  endpoint={window.env.REACT_APP_API_DEALERS}
                  disabled={this.state.isLoading}
                  searchTerm={this.state.searchTerm}
                  onSelectedDealersUpdated={selectedDealers => this.setState({ selectedDealers })}/>
                {/* end: Datatable  */}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
