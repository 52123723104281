import React from 'react'
import Modal from 'react-bootstrap/Modal'

export default class CallOffModal extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      show: props.show
    }
  }

  componentWillReceiveProps(nextProps){
    this.setState(nextProps)
  }

  render() {
    let { show } = this.state
    return (
      <Modal
        show={show}
        onHide={() => this.props.onHide()}
      >
        <Modal.Header closeButton>
          <Modal.Title>Add Services</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="kt-portlet kt-portlet--mobile">
    				<div className="kt-portlet__body">
    					<p>Would you like to add Services to your order before Call Off</p>
            </div>
    			</div>
        </Modal.Body>

        <Modal.Footer>
            <button
              type="button"
              className="btn btn-secondary"
              onClick={() => this.props.onHide()}>Cancel</button>
            <button
              type="button"
              className="btn btn-secondary"
              onClick={() => this.props.onCallOffPressed()}>Call Off</button>
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => this.props.onAddServicesPressed()}>Add Services</button>
        </Modal.Footer>
      </Modal>
    )
  }
}
