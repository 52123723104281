import React from "react"

import DealershipsTable from './components/tables/DealershipsTable'

export default class Dalerships extends React.Component {
  render() {
    return (
      <div>
        <div className="kt-portlet kt-portlet--mobile">
          <div className="kt-portlet__head kt-portlet__head--lg">
            <div className="kt-portlet__head-label">
              <span className="kt-portlet__head-icon">
                <i className="kt-font-brand flaticon-car"></i>
              </span>
              <h3 className="kt-portlet__head-title">
                Your Dealerships
              </h3>
            </div>
            <div className="kt-portlet__head-toolbar">
            </div>
          </div>
          <div className="kt-portlet__body">

          </div>
          <div className="kt-portlet__body kt-portlet__body--fit">

            <DealershipsTable/>

          </div>
        </div>


      </div>
    )
  }
}
