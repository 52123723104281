import React from "react"

import moment from "moment"

import SelectOrdersTable from './components/tables/SelectOrdersTable'

import SearchBar from './components/SearchBar'

import Backend from '../../utils/Backend'

export default class MyVins extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      searchTerm: '',
      isLoading: false,
      selectedOrders: [],
    }

    this.selectOrdersTable = React.createRef();
  }

  render() {
    return (
      <div>
        <div className="row">
          <div className="col-lg-12">
            <div className="kt-portlet kt-portlet--mobile">
              <div className="kt-portlet__head kt-portlet__head--lg">
                <div className="kt-portlet__head-label">
                  <span className="kt-portlet__head-icon">
                    <i className="kt-font-brand flaticon-truck"></i>
                  </span>
                  <h3 className="kt-portlet__head-title">
                    Vins called off
                  </h3>
                </div>
              </div>
              <div className="kt-portlet__body">
                <SearchBar
                   onSearchTermChanged={searchTerm => this.setState({ searchTerm })}
                   showSelectStatusFilters={false}/>
                </div>
                <div className="kt-portlet__body kt-portlet__body--fit">

                {/* begin: Datatable  */}
                <SelectOrdersTable
                  ref={this.selectOrdersTable}
                  endpoint={window.env.REACT_APP_API_MY_VINS}
                  disabled={this.state.isLoading}
                  searchTerm={this.state.searchTerm}
                  onSelectedOrdersUpdated={selectedOrders => this.setState({ selectedOrders })}
                  columns={[
                    {
                      Header: 'VIN',
                      accessor: 'vin'
                    },
                    {
                      Header: "Name",
                      accessor: 'name'
                    },
                    {
                      Header:  "Email",
                      accessor: "email"
                    },
                    {
                      Header: "Garage",
                      accessor: "garageId",
                    },
                    {
                      id: "date",
                      Header: "Date Called Off",
                      accessor: order => moment(order.date).format("DD/MM/YYYY")
                    },
                  ]}
                  />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
