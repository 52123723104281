import React from 'react'

import ReactTable from 'react-table'
import 'react-table/react-table.css'

import DealershipActionsCell from './cells/DealershipActionsCell'

import AuthManager from '../../../../utils/AuthManager'
import Backend from '../../../../utils/Backend'

export default class DealershipsTable extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      dealerships: [],
      filteredDealerships: [],
      isLoading: true,

      // Modals
      showDealershipModal: false,
      selectedDealership: AuthManager.dealership,
    }

    this.successModal = React.createRef();

  }

  componentDidMount(){
    this._loadDealerships()
  }

  refresh(){
    this.setState({
      dealerships: [],
      filteredDealerships: [],
      selectedDealership: null
    }, () => {
      this._loadDealerships()
    })
  }

  _loadDealerships(){
    this.setState({ isloading: true })

    Backend.getDealerships()
    .then(dealerships => {
      this.setState({
        dealerships,
        filteredDealerships: dealerships,
        isLoading: false,
      })
    })
    .catch(error => {
      alert(error.message)
      this.setState({ isLoading: false })
    })
  }

  _onSelectPressed(dealership){
    AuthManager.dealership = dealership
    this.setState({ selectedDealership: dealership })
  }

  _getColumns(){
    return [
      {
        Header: 'Name',
        accessor: 'name'
      },
      {
        Header: 'Address',
        accessor: 'addressStreet',
      },
      {
        id: "Actions",
        Header: 'Actions',
        Cell: rowInfo => {
          let dealership = rowInfo.original
          let isSelected = dealership.code == this.state.selectedDealership.code
          return (
            <DealershipActionsCell
              dealership={dealership}
              isSelected={isSelected}
              onSelectPressed={dealership => this._onSelectPressed(dealership)}
            />
          )
        }
      },
    ]
  }

  render(){
    const columns = this._getColumns()

    return (
      <div>
        <ReactTable
          data={this.state.filteredDealerships}
          columns={columns}
          loading={this.state.isLoading}
          getTdProps={(state, rowInfo, column, instance) => {
            return {
              style: {
                color: 'grey',
                paddingLeft: 10,
              }
            }
          }}
          getTrProps={(state, rowInfo, column, instance) => {
            return {
              style: {
                paddingTop: 10,
                paddingBottom: 10,
              }
            }
          }}
          getTheadProps={(state, rowInfo, column, instance) => {
            return {
              style: {
                boxShadow: '0 1px 1px 0 rgba(0,0,0,0.1)',
                paddingTop: 10,
                paddingBottom: 10,
                textAlign : 'left',
              }
            }
          }}
          getTheadThProps={(state, rowInfo, column, instance) => {
              return {
                style: {
                  textAlign : 'left',
                  paddingLeft: 10
                }
              }
          }}
        />
      </div>
    )
  }
}
