import React from 'react'
import Modal from 'react-bootstrap/Modal'
import Spinner from 'react-bootstrap/Spinner'

import AuthManager from '../../../../utils/AuthManager'
import Backend from '../../../../utils/Backend'
import Email from '../../../../utils/Email'

import moment from 'moment'

export default class CallOffLimitModal extends React.Component {
  constructor(props){
    super(props)

    this.state = this._getState(props)
  }
  componentWillReceiveProps(nextProps){
    this.setState(this._getState(nextProps))
  }

  _getState(props){
    return {
      show: props.show,
      data: props.callOffLimit ? { ...props.callOffLimit } : {}
    }
  }

  _save(){
    if(!this._isFormValid()){
      return
    }

    let { data } = this.state
    data.value = parseInt(data.value)

    this.setState({ isLoading: true })
    this._handleBackend(data)
    .then(callOffLimit => {
      this.props.onUpdated()
    })
    .catch(error => {
      this.setState({
        isLoading: false,
        error: error.message
      })
    })
  }

  _handleBackend(data){
    if(data.id){
      return Backend.updateCallOffLimit(data)
    }else{
      return Backend.saveCallOffLimit(data)
    }
  }

  _updateData(key, value){
    let data = { ...this.state.data }
    data[key] = value
    this.setState({ data, error: null })
  }

  _isFormValid(){
    let {
      data: {
        garage,
        value,
      }
    } = this.state

    let isValid = true
    let error = null

    if(!garage){
      error = "Please specify a valid harage"
      isValid = false
    }
    else if(!value || value < 0){
      error = "Please specify a valid limit"
      isValid = false
    }
    this.setState({ error })

    return isValid
  }

  render() {
    let {
      show,
      error,
      data,
     } = this.state

    if(!show){
     return null
    }

    return (
      <Modal
        show={show}
        onHide={() => this.props.onHide()}
      >
        <Modal.Header closeButton>
          <Modal.Title>Call Off Limit</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="kt-portlet kt-portlet--mobile">
            <div className="form-group col-md-12">
              <p style={{color: 'red'}}>{ error }</p>
            </div>
            <div className="row">
              <div className="form-group col-md-12">
                <input
                  className="form-control"
                  type="text"
                  placeholder="Garage"
                  disabled={data.id != null}
                  value={data.garage}
                  onChange={event => this._updateData("garage", event.target.value) }
                />
              </div>
              <div className="form-group col-md-12">
                <input
                  className="form-control"
                  type="number"
                  placeholder="Limit"
                  value={data.value}
                  onChange={event => this._updateData("value", event.target.value) }
                />
              </div>
            </div>
    			</div>
        </Modal.Body>
        <Modal.Footer>
            <button
              type="button"
              className="btn btn-secondary"
              onClick={() => this.props.onHide()}>Cancel</button>
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => this._save()}>Save</button>
        </Modal.Footer>
      </Modal>
    )
  }
}
