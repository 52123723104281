import React from 'react'

import ReactTable from 'react-table'
import 'react-table/react-table.css'

import selectTableHOC from "react-table/lib/hoc/selectTable";

import FetchHelper from '../../../../utils/FetchHelper'
//import Dealers from '../../../../utils/Dealers'

const SelectTable = selectTableHOC(ReactTable);

export default class DealerCodesTable extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      dealerCodes: [],
      filteredDealerCodes: [],
      selectedDealerCodes: [],
      isLoading: true,
      endpoint: window.env.REACT_APP_API_DELAY_EXCEPTIONS,
      selectAll: false,

    }

    this.successModal = React.createRef();

  }

  componentDidMount(){
    this._load()
  }

  componentWillReceiveProps(nextProps){
    this.setState(nextProps, () => {
      this._search(this.state.searchTerm)
    })
  }

  _search(searchTerm){
    let dealerCodes = this.state.dealerCodes

    searchTerm = searchTerm.toLowerCase()

    let filteredDealerCodes = dealerCodes.filter(dealerCode => {
      let garage = dealerCode.garage.toLowerCase()

      return garage.indexOf(searchTerm) > -1
    })

    this.setState({ filteredDealerCodes })
  }

  refresh(){
    this.setState({
      dealerCodes: [],
      filteredDealerCodes: [],
      selectedDealerCodes: [],
      isLoading: true,
    }, () => {
      this._load()
    })
  }

  _load(){
    FetchHelper.get(this.state.endpoint)
    .then(dealerCodes => {
      this.setState({
        dealerCodes,
        filteredDealerCodes: dealerCodes,
        isLoading: false,
      })
    })
    .catch(error => {
      alert(error.message)
      this.setState({ isLoading: false })
    })
  }

  _getColumns(){
    return [
      {
        Header: 'Garage',
        accessor: 'garage'
      },
    ]
  }

  _isSelected(garage){
    let index =  this.state.selectedDealerCodes.findIndex(dealerCode => dealerCode.garage === garage);
    return index > -1
  }

  _toggleAll(){
    let selectedDealerCodes = []
    if(this.state.selectedDealerCodes.length < this.state.dealerCodes.length){
      selectedDealerCodes = this.state.filteredDealerCodes
    }

    this.setState({ selectedDealerCodes })
    this.props.onSelectedDealerCodesUpdated(selectedDealerCodes)
  }

  _toggleSelection(garage){
    let { dealerCodes } = this.state
    let selectedDealerCodes = [ ...this.state.selectedDealerCodes ]
    let index = selectedDealerCodes.findIndex(dealerCode => dealerCode.garage === garage);

    let isSelected = index > -1

    if(isSelected){
      selectedDealerCodes.splice(index, 1)
    }
    else{
      let dealerIndex = dealerCodes.findIndex(dealerCode => dealerCode.garage === garage);
      selectedDealerCodes.push(dealerCodes[dealerIndex])
    }

    this.setState({ selectedDealerCodes })
    this.props.onSelectedDealerCodesUpdated(selectedDealerCodes)
  }

  _getSelectAll(){
    return this.state.selectedDealerCodes.length == this.state.dealerCodes.length
  }

  render(){
    const columns = this._getColumns()

    return (
      <div>
        <SelectTable
          keyField="garage"
          data={this.state.filteredDealerCodes}
          columns={columns}
          loading={this.state.isLoading}
          isSelected={garage => this._isSelected(garage)}
          selectAll={this._getSelectAll()}
          toggleAll={() => this._toggleAll()}
          toggleSelection={(key, shift, row) => this._toggleSelection(row.garage)}
          selectType="checkbox"
          getTdProps={(state, rowInfo, column, instance) => {
            return {
              style: {
                color: 'grey',
                paddingLeft: 10,
              }
            }
          }}
          getTrProps={(state, rowInfo, column, instance) => {
            return {
              style: {
                paddingTop: 10,
                paddingBottom: 10,
              }
            }
          }}
          getTheadProps={(state, rowInfo, column, instance) => {
            return {
              style: {
                boxShadow: '0 1px 1px 0 rgba(0,0,0,0.1)',
                paddingTop: 10,
                paddingBottom: 10,
                textAlign : 'left',
              }
            }
          }}
          getTheadThProps={(state, rowInfo, column, instance) => {
              return {
                style: {
                  textAlign : 'left',
                  paddingLeft: 10
                }
              }
          }}
        />
      </div>
    )
  }
}
