import React from 'react'

import ReactTable from 'react-table'
import 'react-table/react-table.css'

import selectTableHOC from "react-table/lib/hoc/selectTable";

import FetchHelper from '../../../../utils/FetchHelper'
//import Dealers from '../../../../utils/Dealers'

const SelectTable = selectTableHOC(ReactTable);

export default class DealersTable extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      searchTerm: props.searchTerm,
      dealers: [],
      filteredDealers: [],
      selectedDealers: [],
      isLoading: true,
      endpoint: props.endpoint,
      selectAll: false,

    }

    this.successModal = React.createRef();

  }

  componentDidMount(){
    this._load()
  }

  componentWillReceiveProps(nextProps){
    this.setState(nextProps, () => {
      this._search(this.state.searchTerm)
    })
  }

  _search(searchTerm){
    let dealers = this.state.dealers

    let filteredDealers = dealers.filter(dealer => {
      searchTerm = searchTerm.toLowerCase()
      let code = dealer.code.toLowerCase()
      let name = dealer.name.toLowerCase()

      return code.indexOf(searchTerm) > -1 || name.indexOf(searchTerm) > -1
    })
    
    this.setState({ filteredDealers })
  }

  refresh(){
    this.setState({
      dealers: [],
      filteredDealers: [],
      selectedDealers: [],
      isLoading: true,
    }, () => {
      this._load()
    })
  }

  _load(){
    FetchHelper.get(this.state.endpoint)
    .then(dealers => {
      this.setState({
        dealers,
        filteredDealers: dealers,
        isLoading: false,
      })
    })
    .catch(error => {
      alert(error.message)
      this.setState({ isLoading: false })
    })
  }

  _getColumns(){
    return [
      {
        Header: 'Code',
        accessor: 'code'
      },
      {
        Header: 'Name',
        accessor: 'name'
      },
    ]
  }

  _isSelected(code){
    let index =  this.state.selectedDealers.findIndex(dealer => dealer.code === code);
    return index > -1
  }

  _toggleAll(){
    let selectedDealers = []
    if(this.state.selectedDealers.length < this.state.dealers.length){
      selectedDealers = this.state.filteredDealers
    }

    this.setState({ selectedDealers })
    this.props.onSelectedDealersUpdated(selectedDealers)
  }

  _toggleSelection(code){
    let { dealers } = this.state
    let selectedDealers = [ ...this.state.selectedDealers ]
    let index = selectedDealers.findIndex(dealer => dealer.code === code);

    let isSelected = index > -1

    if(isSelected){
      selectedDealers.splice(index, 1)
    }
    else{
      let dealerIndex = dealers.findIndex(dealer => dealer.code === code);
      selectedDealers.push(dealers[dealerIndex])
    }

    this.setState({ selectedDealers })
    this.props.onSelectedDealersUpdated(selectedDealers)
  }

  _getSelectAll(){
    return this.state.selectedDealers.length == this.state.dealers.length
  }

  render(){
    const columns = this._getColumns()

    return (
      <div>
        <SelectTable
          keyField="code"
          data={this.state.filteredDealers}
          columns={columns}
          loading={this.state.isLoading}
          isSelected={code => this._isSelected(code)}
          selectAll={this._getSelectAll()}
          toggleAll={() => this._toggleAll()}
          toggleSelection={(key, shift, row) => this._toggleSelection(row.code)}
          selectType="checkbox"
          getTdProps={(state, rowInfo, column, instance) => {
            return {
              style: {
                color: 'grey',
                paddingLeft: 10,
              }
            }
          }}
          getTrProps={(state, rowInfo, column, instance) => {
            return {
              style: {
                paddingTop: 10,
                paddingBottom: 10,
              }
            }
          }}
          getTheadProps={(state, rowInfo, column, instance) => {
            return {
              style: {
                boxShadow: '0 1px 1px 0 rgba(0,0,0,0.1)',
                paddingTop: 10,
                paddingBottom: 10,
                textAlign : 'left',
              }
            }
          }}
          getTheadThProps={(state, rowInfo, column, instance) => {
              return {
                style: {
                  textAlign : 'left',
                  paddingLeft: 10
                }
              }
          }}
        />
      </div>
    )
  }
}
