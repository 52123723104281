import React from "react"

import OverviewTable from './components/tables/OverviewTable'
import OrderButton from './components/OrderButton'

import Card from './components/Card'

import SearchBar from './components/SearchBar'
import DataCollection from '../../utils/DataCollection'

export default class Overview extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      searchTerm: '',
      statusFilters: []
    }
  }

  _onOrdersLoaded(orders){
    let calledOff = orders.reduce((total, order) => {
      if(order.status && order.status.toLowerCase() !== "available_for_call_off"){
        total += 1
      }
      return total
    }, 0)

    let inService = orders.reduce((total, order) => {
      if(order.status && order.status.toLowerCase() === "waiting_on_service"){
        total += 1
      }
      return total
    }, 0)

    let inTransit = orders.reduce((total, order) => {
      if(order.status && order.status.toLowerCase() === "in_transit"){
        total += 1
      }
      return total
    }, 0)

    this.setState({ calledOff, inService, inTransit })
  }

  render() {
    return (
      <div>
        <div class="row">
  				<Card
            title="Cars Called Off"
            value={ this.state.calledOff }
          />
          <Card
            title="Cars in Transit"
            value={ this.state.inTransit }
          />
          <Card
            title="Cars in Service"
            value={ this.state.inService }
          />
  			</div>
    		<div className="kt-portlet kt-portlet--mobile">
    			<div className="kt-portlet__head kt-portlet__head--lg">
    				<div className="kt-portlet__head-label">
    					<span className="kt-portlet__head-icon">
    						<i className="kt-font-brand flaticon-car"></i>
    					</span>
    					<h3 className="kt-portlet__head-title">
    						Orders
    					</h3>
    				</div>
    				<div className="kt-portlet__head-toolbar">
    					<div className="kt-portlet__head-wrapper">
    						&nbsp;
    						<OrderButton/>
    					</div>
    				</div>
    			</div>
    			<div className="kt-portlet__body">
    				{/* begin: Search Form  */}
    				<div className="kt-form kt-form--label-right kt-margin-t-20 kt-margin-b-10">
    					<div className="row align-items-center">
    						<div className="col-xl-8 order-2 order-xl-1">
    							 <SearchBar
                      onSearchTermChanged={searchTerm => {
                        this.setState({ searchTerm })
                        DataCollection.track(window.Firebase_Event.LOCAL_SEARCH, {
                           searchTerm
                        })
                      }}
                      onSelectedStatusFiltersChanged={statusFilters => this.setState({ statusFilters })}/>
    						</div>
    						<div className="col-xl-4 order-1 order-xl-2 kt-align-right">
    							<a href="#" className="btn btn-default kt-hidden">
    								<i className="la la-cart-plus"></i> New Order
    							</a>
    							<div className="kt-separator kt-separator--border-dashed kt-separator--space-lg d-xl-none"></div>
    						</div>
    					</div>
    				</div>

    				{/* end: Search Form  */}

    				<div className="kt-portlet__body kt-portlet__body--fit">

    					{/* begin: Datatable  */}
    					<OverviewTable
                searchTerm={this.state.searchTerm}
                statusFilters={this.state.statusFilters}
                onLoaded={orders => this._onOrdersLoaded(orders)}/>
    				</div>
    			</div>
    		</div>
      </div>
    )
  }
}
