import React from 'react'

import { CSVLink, CSVDownload } from "react-csv";
import { withRouter } from 'react-router-dom'

import ReactTable from 'react-table'
import 'react-table/react-table.css'

import moment from 'moment'

import OverviewActionsCell from './cells/OverviewActionsCell'

import DetailModal from '../modals/DetailModal'
import TrackModal from '../modals/TrackModal'
import AddServicesModal from '../modals/AddServicesModal'
import InputModal from '../modals/InputModal'
import CallOffModal from '../modals/CallOffModal'
import SuccessModal from '../modals/SuccessModal'

import Backend from '../../../../utils/Backend'
import Orders from '../../../../utils/Orders'

class OverviewTable extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      orders: [],
      filteredOrders: [],
      isLoading: true,

      // Modals
      showDetailModal: false,
      showTrackModal: false,
      showAddServicesModal: false,
      showQueryCarModal: false,
      selectedOrder: null,
      csvData: []
    }

    this.reactTable = React.createRef();
    this.csvLink = React.createRef();
    this.successModal = React.createRef()
  }

  componentDidMount() {
    this._load()
  }

  componentWillReceiveProps(nextProps) {
    this.setState(nextProps, () => {
      this._search(this.state.searchTerm, this.state.statusFilters)
    })
  }

  _refresh(){
    this.setState({
      orders: [],
      filteredOrders: [],
      isLoading: true,
    }, () => {
      this._load()
    })
  }

  _load(){
    Backend.getFeed()
    .then(orders => {
      this.props.onLoaded(orders)
      this.setState({
        orders,
        isLoading: false
      }, () => {
        this._search(this.state.searchTerm, this.state.statusFilters)
      })
    })
    .catch(error => {
      alert(error.message)
      this.setState({ isLoading: false })
    })
  }

  _search(searchTerm, statusFilters) {
    let orders = this.state.orders

    let filteredOrders = Orders.search(searchTerm, statusFilters, orders)
    this.setState({ filteredOrders })
  }

  _onTrackPressed(order) {
    this.setState({ showTrackModal: true, selectedOrder: order })
  }

  _onAddServicesPressed(order, callOff=false) {
    let serviceBeforeCallOff = !callOff
    this.setState({
      showAddServicesModal: true,
      selectedOrder: order,
      callOff,
      serviceBeforeCallOff
    })
  }

  _onDetailPressed(order) {
    this.setState({ showDetailModal: true, selectedOrder: order })
  }

  _onCallOffPressed(order) {
    this.setState({ showCallOffModal: true, selectedOrder: order })
  }

  _handleQueryCar(order) {
    this.setState({ showTrackModal: false }, () => {
      this.setState({
        showQueryCarModal: true,
        selectedOrder: order
      })
    })
  }

  _queryCar(selectedOrder, query) {
    this._closeModals()
    this.setState({ isLoading: true })

    Backend.queryCars([selectedOrder], query)
      .then(() => {
        this.setState(
          {
            isLoading: false
          },
          () => {
            this.successModal.current.show(
              'Query Sent',
              'You will receive a response via email'
            )
          }
        )
      })
      .catch(error => {
        this.setState({
          isLoading: false
        })
        alert(error.message)
      })
  }

  _addServices(order, services, callOff) {
    this._closeModals()

    this.props.history.push({
      pathname: '/dealer/checkout',
      state: {
        services,
        orders: [order],
        callOff,
      }
    })
  }

  _callOff(order){
    this.setState({
      selectedOrder: null,
      showCallOffModal: false,
      isLoading: true
    })

    Backend.callOff([order])
    .then(response => {

      this.setState({
        isLoading: false,
      }, () => {
        this._refresh()
        if(response.error){
          let error = response.error_description || response.error
          if(error.startsWith("[") && error.endsWith("]")){
            alert(`The following vins could not be called off: ${error}`)
          }else{
            alert(`An unexpected error occurred: ${error}`)
          }
          return
        }
        this.successModal.current.show("Called Off", "")
      })
    })
    .catch(error => {
      this.setState({
        isLoading: false
      })
      alert(error.message)
    })
  }

  _closeModals() {
    this.setState({
      showTrackModal: false,
      showAddServicesModal: false,
      showDetailModal: false,
      showCallOffModal: false,
      showQueryCarModal: false,
      selectedOrder: null,
      query: ''
    })
  }

  _getColumns() {
    return [
      {
        Header: 'VIN',
        accessor: 'vin'
      },
      {
        Header: 'Chassis / Comm',
        accessor: 'chassis'
      },
      {
        id: 'CarType',
        Header: 'Car Type',
        accessor: order => order.make + ' ' + order.modelCode
      },
      {
        id: 'Status',
        Header: 'Status',
        accessor: order => Orders.getFormattedStatus(order),
        getProps: (state, rowInfo, column) => {
          return {
            style: {
              color: rowInfo ? rowInfo.original.hexCode : 'black'
            }
          }
        }
      },
      {
        id: 'ETA',
        Header: 'ETA',
        accessor: order => {
          if(order.estArrival){
            return moment(order.estArrival).format("DD/MM/YYYY hh:mm A")
          } else if(order.etaAvailable){
            return moment(order.targetDeliveryTime).format("DD/MM/YYYY hh:mm A")
          }
          return ""
        }
      },
      {
        id: 'Actions',
        Header: 'Actions',
        width: 200,
        Cell: rowInfo => {
          return (
            <OverviewActionsCell
              order={rowInfo.original}
              onTrackPressed={order => this._onTrackPressed(order)}
              onAddServicesPressed={order => this._onAddServicesPressed(order)}
              onDetailPressed={order => this._onDetailPressed(order)}
              onCallOffPressed={order => this._onCallOffPressed(order)}
            />
          )
        }
      }
    ]
  }

  _renderModals() {
    let { selectedOrder } = this.state

    if (selectedOrder == null) {
      return null
    }

    return (
      <div>
        <DetailModal
          show={this.state.showDetailModal}
          onHide={() => this._closeModals()}
          order={this.state.selectedOrder}
        />
        <TrackModal
          show={this.state.showTrackModal}
          onHide={() => this._closeModals()}
          order={this.state.selectedOrder}
          onQueryCarPressed={order => this._handleQueryCar(order)}
        />
        <AddServicesModal
          show={this.state.showAddServicesModal}
          onHide={() => this._closeModals()}
          orders={[this.state.selectedOrder]}
          serviceBeforeCallOff={this.state.serviceBeforeCallOff}
          onAddServicesPressed={services =>
            this._addServices(this.state.selectedOrder, services, this.state.callOff)
          }
        />

        <CallOffModal
          show={this.state.showCallOffModal}
          onHide={() => this._closeModals()}
          onCallOffPressed={() => this._callOff(this.state.selectedOrder)}
          onAddServicesPressed={() => this._onAddServicesPressed(this.state.selectedOrder, true)}
          />

        <InputModal
          show={this.state.showQueryCarModal}
          onHide={() => this._closeModals()}
          onConfirmPressed={query =>
            this._queryCar(this.state.selectedOrder, query)
          }
          confirmText="Query Car"
          title="Query Car"
          subtitle="Please enter your query below"
        />
      </div>
    )
  }

  _renderExport(){
    if(this.reactTable == null || this.reactTable.current == null){
      return null
    }

    let csvData = this.state.csvData
    let current = this.reactTable.current

    return (
      <div class="row">
        <div className="col-md-12 text-right">
          <a
            className="btn btn-secondary"
            onClick={() => {
              let sortedDta = current.getResolvedState().sortedData
              let csvData = sortedDta.map(data => {
                return {
                  Vin: data.vin,
                  "Chassis / Comm": data.chassis,
                  "Car Type":  data.CarType,
                  Status: data.Status,
                  "ETA": data.ETA
                }
              })

              this.setState({
                csvData
              }, () => {
                 // click the CSVLink component to trigger the CSV download
                 this.csvLink.current.link.click()
              })
          }}>
            Export to CSV
          </a>
          <CSVLink
            data={csvData}
            filename="data.csv"
            className="hidden"
            ref={this.csvLink}
            target="_blank"
          />
        </div>
      </div>
    )
  }

  render() {
    const columns = this._getColumns()

    return (
      <div>
        { this._renderExport()}
        <ReactTable
          ref={this.reactTable}
          data={this.state.filteredOrders}
          columns={columns}
          loading={this.state.isLoading}
          getTdProps={(state, rowInfo, column, instance) => {
            return {
              style: {
                color: 'grey',
                paddingLeft: 10
              }
            }
          }}
          getTrProps={(state, rowInfo, column, instance) => {
            return {
              style: {
                paddingTop: 10,
                paddingBottom: 10
              }
            }
          }}
          getTheadProps={(state, rowInfo, column, instance) => {
            return {
              style: {
                boxShadow: '0 1px 1px 0 rgba(0,0,0,0.1)',
                paddingTop: 10,
                paddingBottom: 10,
                textAlign: 'left'
              }
            }
          }}
          getTheadThProps={(state, rowInfo, column, instance) => {
            return {
              style: {
                textAlign: 'left',
                paddingLeft: 10
              }
            }
          }}
        />
        {this._renderModals()}
        <SuccessModal ref={this.successModal} />
      </div>
    )
  }
}

export default withRouter(OverviewTable)
