import AuthManager from './AuthManager'

// returns an authenticated fetch requests if possible
// these method are just shortcuts for including headers into
// fetch requests
export default class FetchHelper {
  static get(endpoint, data = {}) {
    data['headers'] = AuthManager.getHeaders()

    let statusCode = null
    return fetch(endpoint, data)
      .then(response => {
        statusCode = response.status
        return response.json()
      })
      .then(responseJson => {
        if (statusCode < 200 || statusCode > 299) {
          throw FetchHelper._getError(responseJson)
          return
        }
        return responseJson
      })
  }

  static post(endpoint, data, isMultiPart = false, headers = null) {
    let statusCode = null

    if (headers == null) {
      headers = isMultiPart
        ? AuthManager.getHeaders('multipart/form-data')
        : AuthManager.getHeaders()
    }

    return fetch(endpoint, {
      method: 'POST',
      headers: headers,
      body: isMultiPart ? data : JSON.stringify(data)
    })
      .then(response => {
        statusCode = response.status
        if (statusCode == 204) {
          return response
        }
        return response.json()
      })
      .then(responseJson => {
        if (statusCode < 200 || statusCode > 299) {
          throw FetchHelper._getError(responseJson)
          return
        }
        return responseJson
      })
  }

  static patch(endpoint, data, stringify = true) {
    var headers = AuthManager.getHeaders()
    if (stringify) {
      data = JSON.stringify(data)
    } else {
      headers = AuthManager.getHeaders('multipart/form-data')
    }

    let statusCode = null
    return fetch(endpoint, {
      method: 'PATCH',
      headers: headers,
      body: data
    })
      .then(response => {
        statusCode = response.status
        return response.json()
      })
      .then(responseJson => {
        if (statusCode < 200 || statusCode > 299) {
          throw FetchHelper._getError(responseJson)
        }
        return responseJson
      })
  }

  static delete(endpoint, data) {
    let statusCode = null
    return fetch(endpoint, {
      method: 'DELETE',
      headers: AuthManager.getHeaders(),
      body: JSON.stringify(data)
    })
      .then(response => {
        statusCode = response.status
        if (statusCode == 204) {
          return response
        }
        return response.json()
      })
      .then(responseJson => {
        if (statusCode < 200 || statusCode > 299) {
          throw FetchHelper._getError(responseJson)
          return
        }
        return responseJson
      })
  }

  static _getError(responseJson) {
    let error = null
    if (responseJson.message) {
      error = responseJson.message
    } else if (responseJson.error_description) {
      error = responseJson.error_description
    } else {
      error = responseJson
    }

    if (error.constructor == Object) {
      for (var key in error) {
        error = key + ': ' + error[Object.keys(error)[0]]
        break
      }
    }

    let message = 'An unexpected error occurred'

    if (error instanceof Array) {
      message = error[0]
    } else if (typeof error === 'string') {
      message = error
    }
    if (error.constructor == Object) {
      for (key in error) {
        message = error[key]
      }
    }

    return { error, message }
  }
}
