import React from 'react'
import Modal from 'react-bootstrap/Modal'
import Spinner from 'react-bootstrap/Spinner'

import Backend from '../../../../utils/Backend'

export default class DetailModal extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      order: props.order,
      show: props.show,
      loading: true,
      addOns: [],
    }
  }

  componentDidMount(){
    this._loadDetail(this.state.order)
  }

  componentWillReceiveProps(nextProps){
    let loadDetail = nextProps.order.vin != this.state.order.vin
    this.setState(nextProps, () => {
      if(loadDetail){
        this._loadDetail(this.state.order)
      }
    })
  }

  _loadDetail(order){
    this.setState({ isLoading: true })
    Backend.getAddOns(order)
    .then(addOns => {
      this.setState({
        addOns,
        isLoading: false
      })
    })
    .catch(error => {
      alert(error.message)
      this.setState({ isLoading: false })
    })
  }

  _title(order){
    return order.make + " " + order.modelCode
  }

  _renderAddOns(){
    let { addOns, isLoading } = this.state

    if(isLoading){
      return (
        <div style={{
          width: '100%',
          textAlign: 'center',
          padding: 40
        }}>
          <Spinner animation="border" />
        </div>
      )
    }

    return addOns.map(addOn => {
      return (
        <p> - { addOn.serviceCode } | { addOn.serviceDescription } </p>
      )
    })
  }

  render() {
    let { order } = this.state
    return (
      <Modal
        show={this.state.show}
        onHide={() => this.props.onHide()}
      >
        <Modal.Header closeButton>
          <Modal.Title>{ this._title(order) }</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <h5
            style={{
              width: '100%',
              textAlign: 'center',
            }}
          >
            Services Added
          </h5>

          { this._renderAddOns() }
        </Modal.Body>

        <Modal.Footer>
        </Modal.Footer>
      </Modal>
    )
  }
}
