import React from "react"

export default class Card extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      title: props.title,
      value: props.value
    }
  }

  componentWillReceiveProps(nextProps){
    this.setState(nextProps)
  }

  render() {
    let {
      title,
      value
    } = this.state

    if(value == null){
      return null
    }

    return (
      <div class="col-md-4 col-lg-4 col-xl-4">
        <div class="kt-portlet">
          <div class="kt-portlet__body  kt-portlet__body--fit">
            <div class="kt-widget24">
              <div class="kt-widget24__details">
                <div class="kt-widget24__info">
                  <h4 class="kt-widget24__title">
                    { title }
                  </h4>
                </div>
              </div>
              <div class="kt-widget24__details">
                <span class="kt-widget24__stats kt-font-brand">
                   { value }
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
