import React from "react"

import moment from 'moment'

export default class TimeSlotPicker extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      items: props.items,
      selectedDate: props.selectedDate
    }
  }

  componentWillReceiveProps(nextProps){
    this.setState(nextProps)
  }

  _group(items){
    let key = "_title"
    return items.reduce((objectsByKeyValue, obj) => {
      const value = obj[key];
      objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj);
      return objectsByKeyValue;
    }, {});
  }

  _renderDaySlots(group, selectedDate){
    return group.map((item, index) => {
      let className = "time-slot-container"
      if(item.date == selectedDate){
        className += " time-slot-container-active"
      }
      return (
        <div class={ className } onClick={() => this.props.onDateSelected(item.date)}>
          <h5 class="time-slot-title"> { item.opens + " to " + item.closes } </h5>
          <h6 class="time-slot-subtitle">Baldonnell, Co Dublin</h6>
        </div>
      )
    })
  }

  render() {
    let {
      items,
      selectedDate
    } = this.state

    let formattedItems = items.map((timeSlot, index) => {
      timeSlot._title = timeSlot.date.format('dddd, Do MMM')
      return timeSlot
    })

    let groupedItems = this._group(formattedItems)

    let renderItems = []

    for (var key in groupedItems) {
        if (!Object.prototype.hasOwnProperty.call(groupedItems, key)) {
            continue
        }
        let group = groupedItems[key]
        renderItems.push(
          <div key={ key } class="row date-slot">
            <div class="col-4 date-slot-container">
              <h4 class="date-slot-title"> { key } </h4>
            </div>
            <div class="col-8 time-slots-container">
              { this._renderDaySlots(group, selectedDate) }
            </div>
          </div>
        )
    }

    return renderItems
  }
}
