import React from 'react'
import { withRouter } from 'react-router-dom'

import AuthManager from '../../../utils/AuthManager'

class Header extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      username: AuthManager.currentUser.name,
    }
  }

  _goTo(url) {
    window.$('.dropdown-menu.dropdown-menu-fit').removeClass('show')
    this.props.history.push(url)
  }

  _onLogoutPressed() {
    AuthManager.logout()
    this._goTo('/user/login')
  }

  render() {
    let { username } = this.state
    let initial = username.charAt(0)

    return (
      <div
        id="kt_header"
        className="kt-header kt-grid__item  kt-header--fixed "
      >
        <button
          className="kt-header-menu-wrapper-close"
          id="kt_header_menu_mobile_close_btn"
        >
          <i className="la la-close" />
        </button>
        <div className="kt-header-menu-wrapper" id="kt_header_menu_wrapper">
          <div
            id="kt_header_menu"
            className="kt-header-menu kt-header-menu-mobile  kt-header-menu--layout-default "
          >
            <ul className="kt-menu__nav ">
              <li
                className="kt-menu__item kt-menu__item--submenu kt-menu__item--rel kt-menu__item--active"
                data-ktmenu-submenu-toggle="click"
                aria-haspopup="true"
              >
                {/*
                  <a href="javascript:;" className="kt-menu__link kt-menu__toggle" style={{cursor: 'default'}}>
                  <span className="kt-menu__link-text">
                     Nissan Ireland
                  </span>
                  <i className="kt-menu__ver-arrow la la-angle-right">
                  </i>
                </a>
                */}
                <div className="kt-menu__submenu kt-menu__submenu--classic kt-menu__submenu--left" />
              </li>
            </ul>
          </div>
        </div>

        {/*  end:: Header Menu */}

        {/*  begin:: Header Topbar */}
        <div className="kt-header__topbar">
          {/* begin: Search */}

          {/* end: Search */}

          {/* begin: Notifications */}

          {/* begin: User Bar */}
          <div className="kt-header__topbar-item kt-header__topbar-item--user">
            <div
              className="kt-header__topbar-wrapper"
              data-toggle="dropdown"
              data-offset="0px,0px"
            >
              <div className="kt-header__topbar-user">
                <span className="kt-header__topbar-welcome kt-hidden-mobile">
                  Hi,
                </span>
                <span className="kt-header__topbar-username kt-hidden-mobile">
                  {username}
                </span>

                {/* use below badge element instead the user avatar to display username's first letter(remove kt-hidden class to display it) */}
                <span className="kt-badge kt-badge--username kt-badge--unified-danger kt-badge--lg kt-badge--rounded kt-badge--bold">
                  {initial}
                </span>
              </div>
            </div>
            <div className="dropdown-menu dropdown-menu-fit dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl">
              {/* begin: Head */}
              <div className="kt-user-card kt-user-card--skin-light kt-notification-item-padding-x">
                <div className="kt-user-card__avatar">
                  {/* use below badge element instead the user avatar to display username's first letter(remove kt-hidden class to display it) */}
                  <span className="kt-badge kt-badge--lg kt-badge--rounded kt-badge--bold kt-font-danger">
                    {initial}
                  </span>
                </div>
                <div className="kt-user-card__name">{username}</div>
              </div>

              {/* end: Head */}
              <div className="kt-notification">
                <a
                  onClick={() => this.props.history.push('/admin/settings')}
                  className="kt-notification__item"
                  style={{ cursor: 'pointer' }}
                >
                  <div className="kt-notification__item-icon">
                    <i className="flaticon-settings kt-font-brand" />
                  </div>
                  <div className="kt-notification__item-details">
                    <div className="kt-notification__item-title kt-font-bold">
                      Settings
                    </div>
                  </div>
                </a>
              </div>

              {/* begin: Navigation */}
              <div className="kt-notification">
                <div className="kt-notification__custom">
                  <a
                    onClick={() => this._onLogoutPressed()}
                    target="_blank"
                    className="btn btn-label-brand btn-sm btn-bold"
                  >
                    Log Out
                  </a>
                </div>
              </div>

              {/* end: Navigation */}
            </div>
          </div>

          {/* end: User Bar */}
        </div>

        {/*  end:: Header Topbar */}
      </div>
    )
  }
}

export default withRouter(Header)
