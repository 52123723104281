import React from 'react'
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom'

import Overview from './Overview'
import AddServices from './AddServices'
import CallOff from './CallOff'
import QueryCars from './QueryCars'
import SelfCollect from './SelfCollect'
import Checkout from './Checkout'
import Users from './Users'
import Dealerships from './Dealerships'
import MyVins from './MyVins'

import LeftMenu from './components/LeftMenu'
import MobileHeader from './components/MobileHeader'
import Footer from './components/Footer'
import QuickPanel from './components/QuickPanel'
import Header from './components/Header'
import SubHeader from './components/SubHeader'

export default class App extends React.Component {
  render() {
    return (
      <div>
        <MobileHeader />

        <div className="kt-grid kt-grid--hor kt-grid--root">
          <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">
            <LeftMenu />
            <div
              className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper"
              id="kt_wrapper"
            >
              <Header />
              <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
                <SubHeader />

                {/* begin:: Content */}
                <div
                  className="kt-content  kt-grid__item kt-grid__item--fluid"
                  id="kt_content"
                >
                  <Switch>
                    <Route exact path="/dealer" component={Overview} />
                    <Route
                      path="/dealer/add-services"
                      component={AddServices}
                    />
                    <Route path="/dealer/call-off" component={CallOff} />
                    <Route
                      path="/dealer/self-collect"
                      component={SelfCollect}
                    />
                    <Route path="/dealer/query-cars" component={QueryCars} />
                    <Route path="/dealer/checkout" component={Checkout} />
                    <Route path="/dealer/users" component={Users} />
                    <Route path="/dealer/dealerships" component={Dealerships} />
                    <Route path="/dealer/my-vins" component={MyVins} />
                    <Redirect to="/404" />
                  </Switch>

                  {/*  end:: Content  */}
                </div>
                {/*  begin:: Footer  */}
                <Footer />
                {/*  end:: Footer  */}
              </div>
            </div>
          </div>
          {/*  end:: Page  */}
          {/*  begin::Quick Panel  */}
          <QuickPanel />
          {/*  end::Quick Panel  */}
          {/*  begin::Scrolltop  */}
          <div id="kt_scrolltop" className="kt-scrolltop">
            <i className="fa fa-arrow-up" />
          </div>
        </div>
      </div>
    )
  }
}
