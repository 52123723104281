import React from 'react'

import ReactTable from 'react-table'
import 'react-table/react-table.css'

import UserActionsCell from './cells/UserActionsCell'

import Backend from '../../../../utils/Backend'

export default class UsersTable extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      users: [],
      filteredUsers: [],
      isLoading: true,

      // Modals
      showUserModal: false,
      selectedUser: null,
    }

    this.successModal = React.createRef();

  }

  componentDidMount(){
    this._loadUsers()
  }

  refresh(){
    this.setState({
      users: [],
      filteredUsers: [],
      selectedUser: null
    }, () => {
      this._loadUsers()
    })
  }

  _loadUsers(){
    this.setState({ isloading: true })

    Backend.getStaff()
    .then(users => {
      this.setState({
        users,
        filteredUsers: users,
        isLoading: false,
      })
    })
    .catch(error => {
      alert(error.message)
      this.setState({ isLoading: false })
    })
  }

  _getColumns(){
    return [
      {
        Header: 'Name',
        accessor: 'name'
      },
      {
        Header: 'Email',
        accessor: 'email',
      },
      {
        id: "Actions",
        Header: 'Actions',
        Cell: rowInfo => {
          return (
            <UserActionsCell
              user={rowInfo.original}
              onEditPressed={user => this.props.onEditPressed(user)}
            />
          )
        }
      },
    ]
  }

  render(){
    const columns = this._getColumns()

    return (
      <div>
        <ReactTable
          data={this.state.filteredUsers}
          columns={columns}
          loading={this.state.isLoading}
          getTdProps={(state, rowInfo, column, instance) => {
            return {
              style: {
                color: 'grey',
                paddingLeft: 10,
              }
            }
          }}
          getTrProps={(state, rowInfo, column, instance) => {
            return {
              style: {
                paddingTop: 10,
                paddingBottom: 10,
              }
            }
          }}
          getTheadProps={(state, rowInfo, column, instance) => {
            return {
              style: {
                boxShadow: '0 1px 1px 0 rgba(0,0,0,0.1)',
                paddingTop: 10,
                paddingBottom: 10,
                textAlign : 'left',
              }
            }
          }}
          getTheadThProps={(state, rowInfo, column, instance) => {
              return {
                style: {
                  textAlign : 'left',
                  paddingLeft: 10
                }
              }
          }}
        />
      </div>
    )
  }
}
