import React from "react"
import Spinner from 'react-bootstrap/Spinner'

import SuccessModal from './components/modals/SuccessModal'

import Backend from '../../utils/Backend'

export default class Checkout extends React.Component {
  constructor(props){
    super(props)

    if(this._noCheckoutItems()){
      this._goToDashboard()
      return
    }

    let { orders, services, callOff } = this.props.location.state

    this.state = {
      orders,
      services,
      callOff: callOff || false,
      isLoading: false
    }

    this.successModal = React.createRef();
  }

  _goToDashboard(){
    this.props.history.push({
      pathname: '/dealer'
    })
  }

  _noCheckoutItems(){
    return this.props.location.state == null
  }

  _removeService(serviceToRemove){
    let services = [ ...this.state.services ]

    let index = services.findIndex(service => service.code == serviceToRemove.code)

    services.splice(index, 1)

    this.setState({ services })

    if(services.length == 0){
      this._goToDashboard()
    }
  }

  _getTotal(services, orders){
    let total = 0
    services.map(service => {

      let servicePrice = parseFloat(service.defaultPrice)
      let totalServicePrice = servicePrice * orders.length

      total += totalServicePrice
    })
    return total
  }

  _onCancelPressed(){
    this.props.history.goBack();
  }

  _onCheckoutPressed(){
    this.setState({ isLoading: true })

    let { orders, services, callOff } = this.state

    Backend.checkout(orders, services, callOff)
    .then(() => {
      this.successModal.current.show("Order Placed", "", () => {
        this.props.history.goBack();
      })
    })
    .catch(error => {
      this.setState({ isLoading: false })
      alert(error.message)
    })
  }

  _renderButtons(){

    if(this.state.isLoading){
      return (
        <div style={{
          width: '100%',
          textAlign: 'center',
          padding: 40
        }}>
          <Spinner animation="border" />
        </div>
      )
    }
    return (
      <div className="col-lg-12 kt-align-right">
        <button type="reset" onClick={() => this._onCancelPressed()} className="btn btn-secondary">Cancel Order</button>
        <button type="reset" onClick={() => this._onCheckoutPressed()} className="btn btn-primary">Process Payment</button>
      </div>
    )
  }

  _renderItems(){
    let { orders, services } = this.state

    return services.map(service => {
      let price = parseFloat(service.defaultPrice)

      let totalPrice = price * orders.length

      totalPrice = parseFloat(totalPrice).toFixed(2)
      return (
        <div key={service.code} className="kt-mycart__item">
          <div className="kt-mycart__container">
            <div className="kt-mycart__info">
                { service.description }
              <span className="kt-mycart__desc">
                { service.code }
              </span>
              <div className="kt-mycart__action">
                <span className="kt-mycart__price">€ { totalPrice }</span>
                <span className="kt-mycart__text">for</span>
                <span className="kt-mycart__quantity">{ orders.length }</span>
                <a onClick={() => this._removeService(service)} className="btn btn-label-primary btn-icon"><i className="flaticon-delete"></i></a>
              </div>
            </div>
            <a href="#" className="kt-mycart__pic">
            </a>
          </div>
        </div>
      )
    })
  }

  render() {
    let { orders, services } = this.state

    let total = this._getTotal(services, orders)

    return (
      <div>
        <div className="row">
          <div className="col-lg-12">
            <div className="kt-portlet kt-portlet--mobile">
              <div className="kt-portlet__body">
                <form>
                  {/*  begin:: Mycart  */}
                  <div className="kt-mycart">
                    <div className="kt-mycart__head kt-head">
                      <div className="kt-mycart__info">
                        <h3 className="kt-mycart__title" style={{color: '#6c7293'}}><i className="flaticon2-shopping-cart-1"></i> Your Cart</h3>
                      </div>
                    </div>
                    <div className="kt-mycart__body">
                      { this._renderItems() }
                    </div>
                    <div className="kt-mycart__footer">
                      <div className="kt-mycart__section">
                        <div className="kt-mycart__subtitel">
                          <span>Total</span>
                        </div>
                        <div className="kt-mycart__prices">
                          <span className="kt-font-brand">€ { parseFloat(total).toFixed(2) }</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/*  end:: Mycart  */}
                </form>
              </div>
              <div className="kt-portlet__foot">
                <div className="kt-form__actions">
                  <div className="row">
                    { this._renderButtons() }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <SuccessModal ref={this.successModal}/>
      </div>
    )
  }
}
