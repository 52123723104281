window.Firebase_Event = {}


window.Firebase_Event.APP_OPENED = "app_opened"

window.Firebase_Event.SIGN_IN = "sign_in"
window.Firebase_Event.SIGN_OUT = "sign_out"

window.Firebase_Event.LOCAL_SEARCH = "local_search"

window.Firebase_Event.CALL_OFF = "call_off"
window.Firebase_Event.QUERY_CARS = "query_cars"
window.Firebase_Event.ADD_SERVICES = "add_services"
window.Firebase_Event.SELF_COLLECT = "self_collect"

window.Firebase_Event.CHECKOUT = "checkout"

window.Firebase_Event.TRACK = "track"

window.Firebase_Event.SHARE_TRACKING_LINK = "share_tracking_link"

window.Firebase_Event.DOWNLOAD_POD = "download_pod"

window.Firebase_Event.USER_UPDATED = "user_updated"
window.Firebase_Event.USER_CREATED = "user_created"
