import React from "react"

import BackgroundImage from '../../assets/media/bg/bg-3.jpg'

import AuthManager from '../../utils/AuthManager'
import Email from '../../utils/Email'

export default class Login extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      email: "",
    }
  }

  _isFormValid(){
    let { email, password } = this.state

    if(!Email.isValid(email)){
      this.setState({ error: "Please enter a valid email address" })
      return false
    }

    return true
  }

  _cancelPressed(){
    this.props.history.push("/user/login");
  }

  _handleSendCodePressed(){
    if(!this._isFormValid()){
      return
    }
    let { email } = this.state

    AuthManager.requestResetPassword(email)
    .then(() => {
      this.props.history.push("/user/reset-password/"+email);
    })
    .catch(error => {
      this.setState({ isLoading: false, error: error.message })
    })
  }

  _renderError(){
    let { error } = this.state

    if(!error){
      return null
    }

    return (
      <div class="kt-alert kt-alert--outline alert alert-danger alert-dismissible" role="alert">
        <span>{error}</span>
      </div>
    )
  }

  render() {
    return (
			<div className="kt-grid kt-grid--hor kt-grid--root  kt-login kt-login--v3 kt-login--signin" id="kt_login">
				<div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor" style={{backgroundImage: 'url(' + BackgroundImage + ')'}}>
					<div className="kt-grid__item kt-grid__item--fluid kt-login__wrapper">
						<div className="kt-login__container">
							<div className="kt-login__logo">
								<a href="#">
									<img src={require("../../assets/img/logo.png")} height="80px"/>
								</a>
							</div>
							<div className="kt-login__signin">
								<div className="kt-login__head">
									<h3 className="kt-login__title">Forgot Password</h3>
                  <p className="kt-login__desc">To request a password reset code, please submit the email address linked to your account</p>
								</div>
								<div className="kt-form">
                  { this._renderError() }
									<div className="input-group">
										<input
                      className="form-control"
                      type="email"
                      placeholder="Email"
                      value={this.state.email}
                      onChange={event => this.setState({ email: event.target.value }) }/>
									</div>
									<div className="kt-login__actions">
										<button onClick={() => this._handleSendCodePressed()} className="btn btn-brand btn-pill btn-elevate kt-login__btn-primary">SEND CODE</button>
                  </div>
                  <div className="kt-login__actions">
                    <button onClick={() => this._cancelPressed()} className="btn  btn-pill btn-elevate kt-login__btn-secondary">CANCEL</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
    )
  }
}
