import React from 'react'
import Spinner from 'react-bootstrap/Spinner'

import '../../App.css'

import moment from 'moment'

export default class MapCard extends React.Component {
  _renderDetails(order){

    if (!this.props.order) {
      return (
        <div style={{
          textAlign: 'center',
          paddingTop: 20,
          paddingBottom: 20
        }}>
          <Spinner animation="border" />
        </div>
      )
    }

    let targetDate = '-'
    if (order.targetDate && order.targetDate != '') {
      targetDate = moment(order.targetDate).format('DD/MM/YYYY')
    }
    let actualDate = '-'
    if (order.etaAvailable && order.targetDeliveryTime != '') {
      actualDate = moment(order.targetDeliveryTime).format('DD/MM/YYYY')
    }
    return (
      <>
        <div style={{ flex: 1, textAlign: 'center' }}>
          <span style={{ fontSize: 19, fontWeight: 500 }}>
            BALDONNEL -> {order.finalOrganization}
          </span>
        </div>
        <div
          style={{
            paddingLeft: 10,
            display: 'flex',
            justifyContent: 'space-between'
          }}
        >
          <span>
            <span style={{ fontWeight: 500, fontSize: 17, paddingRight: 5 }}>
              {order.make} {order.model}
            </span>
            <span style={{ fontSize: 16 }}>{order.vin}</span>
          </span>
          <span style={{ fontWeight: 500, fontSize: 13, color: 'red' }}>
            <li>WAITING ON SERVICE</li>
          </span>
        </div>

        <div className="card-table">
          <div className="card-row">
            <span
              className="card-item"
              style={{ fontWeight: 500, fontSize: 13 }}
            >
              Chassis/Comm:
            </span>
            <span
              className="card-item"
              style={{ fontWeight: 500, fontSize: 13 }}
            >
              SLA ETA:
            </span>
            <span
              className="card-item"
              style={{ fontWeight: 500, fontSize: 13 }}
            >
              Actual ETA:
            </span>
          </div>
          <div className="card-row">
            <span
              className="card-item"
              style={{ fontWeight: 300, fontSize: 13 }}
            >
              {order.chassis}
            </span>
            <span
              className="card-item"
              style={{ fontWeight: 300, fontSize: 13 }}
            >
              {targetDate}
            </span>
            <span
              className="card-item"
              style={{ fontWeight: 300, fontSize: 13 }}
            >
              {actualDate}
            </span>
          </div>
        </div>
      </>
    )
  }
  render() {
    let order = this.props.order

    return (
      <div className="map-card">
        <div style={{ flexDirection: 'row' }}>
          { this._renderDetails(order)}
        </div>
      </div>
    )
  }
}
