import React from 'react'

import SharedApp from './pages/shared/App'
import DealerApp from './pages/dealer/App'
import AdminApp from './pages/admin/App'
import Tracking from './pages/shared/Tracking'

import DealerAuthenticatedRoute from './pages/dealer/components/AuthenticatedRoute'
import AdminAuthenticatedRoute from './pages/admin/components/AuthenticatedRoute'

import UnauthenticatedRoute from './components/UnauthenticatedRoute'

import NotFound from './pages/shared/NotFound'
import Pagination from './pages/dealer/components/tables/Pagination'

import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom'

import { ReactTableDefaults } from 'react-table'

import './assets/css/login-v3.default.css'
import './assets/vendors/base/vendors.bundle.css'
import './assets/demo/default/base/style.bundle.css'
import './assets/demo/default/skins/header/base/light.css'
import './assets/demo/default/skins/header/menu/light.css'
import './assets/demo/default/skins/brand/light.css'
import './assets/demo/default/skins/aside/light.css'
import './assets/css/custom.css'

import './constants/Event.js'

window.$ = window.jQuery = require('jquery')

window.env = process.env

Object.assign(ReactTableDefaults, {
  minRows: 9,
  PaginationComponent: Pagination
})

export default class App extends React.Component {
  componentDidMount() {
    let scripts = []
    scripts.push(
      this._getScript(window.env.PUBLIC_URL + '/assets/js/custom.js')
    )
    scripts.push(
      this._getScript(
        window.env.PUBLIC_URL + '/assets/vendors/base/vendors.bundle.js'
      )
    )
    scripts.push(
      this._getScript(
        window.env.PUBLIC_URL + '/assets/demo/default/base/scripts.bundle.js'
      )
    )

    this._addScripts(scripts)
  }

  _getScript(path) {
    const script = document.createElement('script')

    script.src = path

    return script
  }

  // TO:DO remove need for sleep, the issue is vendors.bundle.js, takes too long to load
  // and scripts.bundle.js executes before it finishes causing all sorts of errors.
  async _addScripts(scripts) {
    for (var i = 0; i < scripts.length; i++) {
      let script = scripts[i]
      document.body.appendChild(script)
      await this._sleep(500)
    }
  }

  _sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms))
  }

  render() {
    return (
      <BrowserRouter>
        <Switch>
          <DealerAuthenticatedRoute path="/dealer" component={DealerApp} />
          <AdminAuthenticatedRoute path="/admin" component={AdminApp} />
          <UnauthenticatedRoute path="/user" component={SharedApp} />
          <Route path="/track/:token" component={Tracking} />
          <Redirect exact path="/" to={'/user/login'} />
          <Route path="/404" component={NotFound} />
          <Redirect to="/404" />
        </Switch>
      </BrowserRouter>
    )
  }
}
