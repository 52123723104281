import React from 'react'
import Modal from 'react-bootstrap/Modal'

import SelectOrdersTable from '../tables/SelectOrdersTable'
import AddServicesTable from '../tables/AddServicesTable'

import Orders from '../../../../utils/Orders'

export default class AddServicesModal extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      show: props.show,
      orders: props.orders,
      laoding: true,
      selectedServices: [],
    }
  }

  componentWillReceiveProps(nextProps){
    this.setState(nextProps, () => {
      this._loadServices()
    })
  }

  _loadServices(){
    let { orders } = this.state


  }

  _renderAddServices(){
    if(this.state.selectedServices.length == 0){
      return null
    }
    return (
      <button
        type="button"
        className="btn btn-primary"
        onClick={() => this.props.onAddServicesPressed(this.state.selectedServices)}>Add Services</button>
    )
  }

  render() {
    let { show, orders } = this.state
    return (
      <Modal
        show={show}
        onHide={() => this.props.onHide()}
        dialogClassName={'modal-dialog-centered modal-xl'}
      >
        <Modal.Header closeButton>
          <Modal.Title>Choose which services to add</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="kt-portlet kt-portlet--mobile">
    				<div className="kt-portlet__body">
    					<p>Would you like to add Services to your order before Call Off</p>
            </div>
    			</div>
          <AddServicesTable
            orders={orders}
            serviceBeforeCallOff={this.props.serviceBeforeCallOff}
            onSelectedServicesUpdated={selectedServices => this.setState({ selectedServices })}
          />
        </Modal.Body>

        <Modal.Footer>
            <button
              type="button"
              className="btn btn-secondary"
              onClick={() => this.props.onHide()}>Cancel</button>
            { this._renderAddServices() }
        </Modal.Footer>
      </Modal>
    )
  }
}

AddServicesModal.defaultProps = {
  serviceBeforeCallOff: true
}
