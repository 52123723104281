import React from "react"

import SelectOrdersTable from './components/tables/SelectOrdersTable'

import SuccessModal from './components/modals/SuccessModal'
import InputModal from './components/modals/InputModal'

import SearchBar from './components/SearchBar'

import Backend from '../../utils/Backend'

export default class QueryCars extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      searchTerm: '',
      isLoading: false,
      selectedOrders: [],
      showQueryCarModal: false
    }

    this.successModal      = React.createRef();
    this.selectOrdersTable = React.createRef();

  }

  _onQueryCarsPressed(){
    this.setState({ showQueryCarModal: true })
  }

  _queryCars(selectedOrders, query){
    this.setState({
      selectedOrders: [],
      query: '',
      showQueryCarModal: false,
      isLoading: true
    })

    Backend.queryCars(selectedOrders, query)
    .then(() => {
      this.setState({
        isLoading: false,
      }, () => {
        this.successModal.current.show("Query Sent", "You will receive a response via email")
        this.selectOrdersTable.current.refresh()
      })
    })
    .catch(error => {
      this.setState({
        isLoading: false
      })
      alert(error.message)
    })
  }

  _closeModals(){
    this.setState({
      showQueryCarModal: false
    })
  }

  _renderQueryButton(){
    let { selectedOrders } = this.state
    if(selectedOrders.length == 0){
      return null
    }

    return (
      <div className="kt-form kt-form--label-align-right kt-margin-t-20">
        <div className="row align-items-center">
          <div className="col-xl-12">
            <div className="kt-form__group kt-form__group--inline">
              <div className="kt-form__label kt-form__label-no-wrap">
                <label className="kt-font-bold kt-font-danger-">Selected
                  <span id="kt_datatable_selected_number"> { selectedOrders.length }</span> records:</label>
              </div>
              <div className="kt-form__control">
                <div className="btn-toolbar">
                  <div className="dropdown">
                    <button
                      type="button"
                      className="btn btn-brand btn-sm"
                      onClick={() => this._onQueryCarsPressed()}
                    >
                      Query Selected
                    </button>
                  </div>
                  &nbsp;&nbsp;&nbsp;
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  render() {
    return (
      <div>
        <div className="row">
          <div className="col-lg-12">
            <div className="kt-portlet kt-portlet--mobile">
              <div className="kt-portlet__head kt-portlet__head--lg">
                <div className="kt-portlet__head-label">
                  <span className="kt-portlet__head-icon">
                    <i className="kt-font-brand flaticon2-line-chart"></i>
                  </span>
                  <h3 className="kt-portlet__head-title">
                    Choose which vehicles to query
                  </h3>
                </div>
              </div>
              <div className="kt-portlet__body">

                <SearchBar
                   onSearchTermChanged={searchTerm => this.setState({ searchTerm })}
                   showSelectStatusFilters={false}/>

                { this._renderQueryButton()}

              </div>
              <div className="kt-portlet__body kt-portlet__body--fit">

                {/* begin: Datatable  */}
                <SelectOrdersTable
                  ref={this.selectOrdersTable}
                  endpoint={window.env.REACT_APP_API_FEED}
                  disabled={this.state.isLoading}
                  searchTerm={this.state.searchTerm}
                  onSelectedOrdersUpdated={selectedOrders => this.setState({ selectedOrders })}/>
                {/* end: Datatable  */}

                <InputModal
                  show={this.state.showQueryCarModal}
                  onHide={() => this._closeModals()}
                  onConfirmPressed={query => this._queryCars(this.state.selectedOrders, query)}
                  confirmText="Query Car(s)"
                  title="Query Car(s)"
                  subtitle="Please enter your query below"
                  />

                  <SuccessModal ref={this.successModal}/>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
