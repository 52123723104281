import React from 'react'

import MapCard from './MapCard'
import { Map, InfoWindow, Marker, GoogleApiWrapper } from 'google-maps-react'

import Backend from '../../utils/Backend'
import Orders from '../../utils/Orders'

import '../../App.css'

import mapStyle from '../../assets/map/mapStyle.json'
const FINAL_MAP_MARKER = require('../../assets/map/garage.png')
const MAP_MARKER = require('../../assets/map/car.png')

window.$ = window.jQuery = require('jquery')

export class Tracking extends React.Component {
  constructor() {
    super()
    this.state = {
      loading: true,
      trackingDetails: null,
      // MAP
      center: {
        lat: 53.3167,
        lng: -6.236245
      },
      zoom: 4,
      markers: []
    }
  }

  componentDidMount() {
    this._getTrackingDetails()
  }

  _goTo404() {
    this.props.history.push({
      pathname: '/404'
    })
  }

  _getTrackingDetails() {
    Backend.getTrackingDetails(this.props.match.params.token)
      .then(trackingDetails => {
        let markers = [
          {
            lat: trackingDetails.gpsNorth,
            lng: trackingDetails.gpsEast
          },
          {
            lat: trackingDetails.finalOrgGpsNorth,
            lng: trackingDetails.finalOrgGpsEast
          }
        ]

        this.setState({
          trackingDetails,
          markers,
          loading: false
        })
      })
      .catch(error => {
        this.setState({ loading: false })
        console.log(error)
        this._goTo404()
      })
  }

  _mapLoaded(mapProps, map) {
    this._setMapStyle(map)
    let { markers } = this.state
    if (markers.length == 0) {
      setTimeout(() => {
        this._mapLoaded(mapProps, map)
      }, 500)
      return
    }
    const bounds = this._getMapBounds(mapProps, map, markers)
    map.fitBounds(bounds, { paddingBottom: 1000 })
  }

  _setMapStyle(map) {
    map.setOptions({
      styles: mapStyle
    })
  }

  _getMapBounds(mapProps, map, markers) {
    const { google } = this.props
    const bounds = new google.maps.LatLngBounds()

    markers.forEach(marker => {
      bounds.extend(new google.maps.LatLng(marker.lat, marker.lng))
    })
    this._getDirections(map)
    return bounds
  }

  _getDirections(map) {
    const { google } = this.props
    let { markers } = this.state
    let { trackingDetails } = this.state
    // directionsService requires floats not strings
    markers = markers.map(marker =>
      Object.entries(marker).reduce(
        (obj, [key, value]) => ((obj[key] = parseFloat(value)), obj),
        {}
      )
    )
    const directionsService = new google.maps.DirectionsService()
    const directionsDisplay = new google.maps.DirectionsRenderer()
    directionsDisplay.setOptions({
      suppressMarkers: true
    })
    var request = {
      origin: markers[0],
      destination: markers[1],
      travelMode: 'DRIVING'
    }
    directionsService.route(request, function(result, status) {
      if (status === 'OK') {
        directionsDisplay.setMap(map)
        directionsDisplay.setDirections(result)
      } else {
        window.alert('Directions request failed due to ' + status)
      }
    })
  }

  _renderMarkers(trackingDetails, google){
    if(!trackingDetails){
      return null
    }

    let markers = [
      {
        lat: trackingDetails.gpsNorth,
        lng: trackingDetails.gpsEast,
        icon: MAP_MARKER
      },
      {
        lat: trackingDetails.finalOrgGpsNorth,
        lng: trackingDetails.finalOrgGpsEast,
        icon: FINAL_MAP_MARKER
      },
    ]

    return markers.map(marker => {
      return (
        <Marker
          position={{
            lat: marker.lat,
            lng: marker.lng
          }}
          icon={{
            url: marker.icon,
            anchor: new google.maps.Point(12, 12),
            scaledSize: new google.maps.Size(32, 32)
          }}
        />
      )
    })
  }

  render() {
    let { trackingDetails } = this.state
    const { google } = this.props
    return (
      <div>
        <Map
          style={{ height: '100vh', width: '100vw' }}
          google={this.props.google}
          zoom={this.state.zoom}
          streetViewControl={false}
          mapTypeControl={false}
          initialCenter={this.state.center}
          onReady={(mapProps, map) => this._mapLoaded(mapProps, map)}
        >
          { this._renderMarkers(trackingDetails, google) }
        </Map>
        <MapCard order={this.state.trackingDetails} />
      </div>
    )
  }
}

export default GoogleApiWrapper({
  apiKey: process.env.REACT_APP_GMAPS_KEY
})(Tracking)
