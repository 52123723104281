import React from 'react'

import CallOffLimitsTable from './components/tables/CallOffLimitsTable'
import SearchBar from './components/SearchBar'
import TextField from './components/TextField'

import InputModal from '../dealer/components/modals/InputModal'
import ConfirmModal from '../dealer/components/modals/ConfirmModal'

import AuthManager from '../../utils/AuthManager'
import Backend from '../../utils/Backend'

export default class CallOffLimits extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isLoading: false,
      searchTerm: "",
    }
    this.table = React.createRef()
  }

  _renderActionButtons(){
    return (
      <div className="kt-form kt-form--label-align-right kt-margin-t-20">
        <div className="row align-items-center">
          <div className="col-lg-12 text-right">
            <button
              type="button"
              className="btn btn-brand btn-sm"
              onClick={() => this.table.current.addCallOff()}
            >
              Add Call Off Limit
            </button>
          </div>
        </div>
      </div>
    )
  }

  render() {
    return (
      <>
        <div className="kt-portlet mt-5">

          <div className="kt-portlet__head kt-portlet__head--lg">
            <div className="kt-portlet__head-label">
              <span className="kt-portlet__head-icon">
                <i className="kt-font-brand flaticon-settings" />
              </span>
              <h3 className="kt-portlet__head-title">Call Off Limits</h3>
            </div>
          </div>

          <div className="kt-portlet__body">
            <SearchBar
               placeholder="Search Garage ID"
               onSearchTermChanged={searchTerm => this.setState({ searchTerm })}
               showSelectStatusFilters={false}
            />
            {this._renderActionButtons() }
          </div>

          <div className="kt-portlet__body">
            {/* begin: Datatable  */}
            <CallOffLimitsTable
              ref={this.table}
              searchTerm={this.state.searchTerm}
            />
          </div>

        </div>
      </>
    )
  }
}
