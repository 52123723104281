import React from 'react'


export default class UserActionsCell extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      dealership: props.dealership,
      isSelected: props.isSelected
    }
  }

  componentWillReceiveProps(nextProps){
    this.setState(nextProps)
  }

  _renderSelect(dealership, isSelected){
    if(isSelected){
      return
    }

    return (
      <a title="Select"
        className="btn btn-secondary"
        onClick={() => this.props.onSelectPressed(dealership)}
        style={{ paddingLeft: 8, paddingRight: 8}}
      >
        Select
      </a>
    )
  }

  render() {
    let { dealership, isSelected } = this.state
    return (
      <span style={{overflow: 'visible', position: 'relative', width: '110px'}}>
        { this._renderSelect(dealership, isSelected)}
      </span>
    )
  }
}
