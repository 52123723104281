import React from 'react'


export default class UserActionsCell extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      user: props.user
    }
  }

  componentWillReceiveProps(nextProps){
    this.setState(nextProps)
  }

  _renderEdit(user){
    return (
      <a title="Edit User"
        className="btn btn-sm btn-clean btn-icon btn-icon-sm"
        onClick={() => this.props.onEditPressed(user)}
      >
        <i className="flaticon-edit"></i>
      </a>
    )
  }

  render() {
    let { user } = this.state
    return (
      <span style={{overflow: 'visible', position: 'relative', width: '110px'}}>
        { this._renderEdit(user)}
      </span>
    )
  }
}
