import React from 'react'

import ReactTable from 'react-table'
import 'react-table/react-table.css'

import ConfirmModal from '../../../dealer/components/modals/ConfirmModal'
import CallOffLimitModal from '../modals/CallOffLimitModal'

import Backend from '../../../../utils/Backend'
import FetchHelper from '../../../../utils/FetchHelper'


export default class CallOffLimitsTable extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      callOffs: [],
      filteredCallOffs: [],
      isLoading: true,
      endpoint: window.env.REACT_APP_API_CALL_OFF_LIMITS,
      selectAll: false,
    }

    this.table = React.createRef()
  }

  componentDidMount(){
    this._load()
  }

  componentWillReceiveProps(nextProps){
    this.setState(nextProps, () => {
      this._search(this.state.searchTerm)
    })
  }

  _search(searchTerm){
    let callOffs = this.state.callOffs

    searchTerm = searchTerm.toLowerCase()

    let filteredCallOffs = callOffs.filter(dealerCode => {
      let garage = dealerCode.garage.toLowerCase()

      return garage.indexOf(searchTerm) > -1
    })

    this.setState({ filteredCallOffs })
  }

  refresh(){
    this.setState({
      callOffs: [],
      filteredCallOffs: [],
      isLoading: true,
    }, () => {
      this._load()
    })
  }

  addCallOff(){
    this.setState({
      selectedCallOffLimit: null,
      showCallOffLimitModal: true
    })
  }

  _load(){
    FetchHelper.get(this.state.endpoint)
    .then(callOffs => {
      this.setState({
        callOffs,
        filteredCallOffs: callOffs,
        isLoading: false,
      })
    })
    .catch(error => {
      alert(error.message)
      this.setState({ isLoading: false })
    })
  }

  _deleteCallOff(){
    let { selectedCallOffLimit } = this.state
    Backend.deleteCallOffLimit(selectedCallOffLimit)
    .then(() => {
      this._onDeleted()
    })
    .catch((err) => {
      if(err && err.message && err.message.indexOf('"Deleted" is not valid JSON') > -1){
        this._onDeleted()
      }else{
        console.error('err')
      }
    })
  }

  _onDeleted(){
    this.setState({
      selectedCallOffLimit: false,
      showDeleteCallOffLimitModal: false
    }, () => alert("Deleted Call Off Limit!"))

    this.refresh()
  }

  _getColumns(){
    return [
      {
        Header: 'Garage',
        accessor: 'garage'
      },
      {
        Header: 'Limit',
        accessor: 'value'
      },
      {
        id: "Actions",
        Header: 'Actions',
        Cell: rowInfo => {
          let callOffLimit = rowInfo.original
          return (
            <>
              <a title="Edit "
                className="btn btn-sm btn-clean btn-icon btn-icon-sm"
                onClick={() => {
                  this.setState({
                    selectedCallOffLimit: callOffLimit,
                    showCallOffLimitModal: true
                  })
                }}
              >
                <i className="flaticon-edit"></i>
              </a>
              <a title="Delete "
                className="btn btn-sm btn-clean btn-icon btn-icon-sm"
                onClick={() => this.setState({
                  selectedCallOffLimit: callOffLimit,
                  showDeleteCallOffLimitModal: true
                })}
              >
                <i className="flaticon-delete"></i>
              </a>
            </>
          )
        }
      }
    ]
  }


  render(){
    let { selectedCallOffLimit, showCallOffLimitModal } = this.state
    const columns = this._getColumns()

    return (
      <div>
        <ReactTable
          keyField="garage"
          ref={this.table}
          data={this.state.filteredCallOffs}
          columns={columns}
          loading={this.state.isLoading}
          getTdProps={(state, rowInfo, column, instance) => {
            return {
              style: {
                color: 'grey',
                paddingLeft: 10,
              }
            }
          }}
          getTrProps={(state, rowInfo, column, instance) => {
            return {
              style: {
                paddingTop: 10,
                paddingBottom: 10,
              }
            }
          }}
          getTheadProps={(state, rowInfo, column, instance) => {
            return {
              style: {
                boxShadow: '0 1px 1px 0 rgba(0,0,0,0.1)',
                paddingTop: 10,
                paddingBottom: 10,
                textAlign : 'left',
              }
            }
          }}
          getTheadThProps={(state, rowInfo, column, instance) => {
              return {
                style: {
                  textAlign : 'left',
                  paddingLeft: 10
                }
              }
          }}
        />


        <ConfirmModal
          show={this.state.showDeleteCallOffLimitModal}
          onHide={() => this.setState({
            selectedCallOffLimit: null,
            showDeleteCallOffLimitModal: false
          })}
          onConfirmPressed={() => this._deleteCallOff()}
          confirmText="Delete"
          title="Delete Call Off Limit"
          subtitle="Are you sure? You cannot undo this action."
        />

        <CallOffLimitModal
          show={showCallOffLimitModal}
          callOffLimit={selectedCallOffLimit}
          onUpdated={() => {
            this.setState({
              selectedCallOffLimit: null,
              showCallOffLimitModal: false
            })
            this.refresh()
          }}
          onHide={() => this.setState({
            selectedCallOffLimit: null,
            showCallOffLimitModal: false
          })}
        />
      </div>
    )
  }
}
