import React from "react"

import Backend from '../../../utils/Backend'
import Orders from '../../../utils/Orders'

import TextareaAutosize from 'react-autosize-textarea';

export default class SearchBar extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      isLoading: true,
      statusFilters: [],
      showSelectStatusFilters: props.showSelectStatusFilters
    }
  }

  componentDidMount(){
    Backend.getFeedStatusFilters()
    .then(statusFilters => {
      this.setState({
        statusFilters,
        isLoading: false
      })
    })
  }

  _onSelectStatusFilterChanged(statusFilterId){
    let { statusFilters } = this.state
    let index = statusFilters.findIndex(status => status.id == statusFilterId)

    let selectedStatusFilters = []
    if(index > -1){
      selectedStatusFilters.push(statusFilters[index])
    }

    this.props.onSelectedStatusFiltersChanged(selectedStatusFilters)
  }

  _renderSelectStatusFilters(){

    let { isLoading, showSelectStatusFilters } = this.state
    if(isLoading || !showSelectStatusFilters){
      return null
    }

    return (
      <div className="kt-form__group kt-form__group--inline">
        <div className="kt-form__label">
          <label>Status:</label>
        </div>
        <div className="kt-form__control">
          <select
            className="form-control bootstrap-select"
            onChange={event => this._onSelectStatusFilterChanged(event.target.value)}>
            <option value="">ALL</option>
            { this._renderSelectStatusFilterOptions() }
          </select>
        </div>
      </div>
    )
  }

  _renderSelectStatusFilterOptions(){
    return this.state.statusFilters.map(statusFilter => {
      let title = Orders.formattedStatus(statusFilter.name)
      return (
        <option value={ statusFilter.id }>{ title }</option>
      )
    })
  }

  render() {
    return (
      <div className="row align-items-center">
       <div className="col-md-4 kt-margin-b-20-tablet-and-mobile">
         <div className="kt-input-icon kt-input-icon--left">
           <TextareaAutosize
             className="form-control"
             placeholder={this.props.placeholder}
             onChange={event => this.props.onSearchTermChanged(event.target.value) }/>
           <span className="kt-input-icon__icon kt-input-icon__icon--left">
             <span><i className="la la-search"></i></span>
           </span>
         </div>
       </div>
       <div className="col-md-4 kt-margin-b-20-tablet-and-mobile">
           { this._renderSelectStatusFilters() }
       </div>
     </div>
    )
  }
}

SearchBar.defaultProps = {
  showSelectStatusFilters: true,
  placeholder: "Search VIN or Comm..."
}
