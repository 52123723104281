import React from 'react'

import AuthManager from '../../../../../utils/AuthManager'

export default class OverviewActionsCell extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      order: props.order
    }
  }

  componentWillReceiveProps(nextProps){
    this.setState(nextProps)
  }

  _renderTrack(order){
    if(!order.tracking){
      return null
    }

    return (
      <a title="Track Order"
        className="btn btn-sm btn-clean btn-icon btn-icon-sm"
        onClick={() => this.props.onTrackPressed(order)}
      >
        <i className="flaticon-placeholder-2"></i>
      </a>
    )
  }

  _renderCallOff(order){
    if(order.status !== "AVAILABLE_FOR_CALL_OFF"){
      return null
    }

    return (
      <a title="Call Off"
        className="btn btn-sm btn-clean btn-icon btn-icon-sm"
        onClick={() => this.props.onCallOffPressed(order)}
      >
        <i className="flaticon-truck"></i>
      </a>
    )
  }

  _renderAddServices(order){
    if(!order.availableForService){
      return null
    }
    return (
      <a title="Add Service"
        className="btn btn-sm btn-clean btn-icon btn-icon-sm"
        onClick={() => this.props.onAddServicesPressed(order)}
      >
        <i className="flaticon2-plus"></i>
      </a>
    )
  }

  _renderDetail(order){
    return (
      <a title="Detail"
        className="btn btn-sm btn-clean btn-icon btn-icon-sm"
        onClick={() => this.props.onDetailPressed(order)}
      >
        <i className="flaticon-info"></i>
      </a>
    )
  }

  _renderProofOfDelivery(order){
    if(order.status !== "DELIVERED"){
      return null
    }
    return (
      <a title="POD"
        className="btn btn-sm btn-clean btn-icon btn-icon-sm"
        target="_blank"
        href={ window.env.REACT_APP_API_DOWNLOAD_POD +"?vin=" +order.vin }
      >
        <i className="flaticon2-download"></i>
      </a>
    )
  }

  _renderPreDeliveryInspection(order){
    return (
      <a title="Pre Delivery Inspection"
        className="btn btn-sm btn-clean btn-icon btn-icon-sm"
        target="_blank"
        href={ window.env.REACT_APP_API_DOWNLOAD_PDI +"?vin=" +order.vin + "&token=" + AuthManager.token }
      >
        <i className="flaticon-file-2"></i>
      </a>
    )
  }


  render() {
    let { order } = this.state
    return (
      <span style={{overflow: 'visible', position: 'relative', width: '110px'}}>
        { this._renderTrack(order)}
        { this._renderCallOff(order)}
        { this._renderAddServices(order)}
        { this._renderDetail(order) }
        { this._renderPreDeliveryInspection(order)}
        { this._renderProofOfDelivery(order)}

      </span>
    )
  }
}
