import React from 'react'
import Modal from 'react-bootstrap/Modal'

import Backend from '../../../../utils/Backend'

const SHOW_MODAL_DURATION = 2000

export default class SuccessModal extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      visible: false,
      title: '',
      message: '',
    }
  }

  show(title, message, callback=() => {}) {

    this.setState({
      title,
      message,
      visible: true
    })
    setTimeout(() => {
      this._hide()
      callback()
    }, SHOW_MODAL_DURATION)
  }

  _changeVisibility(visible) {
    this.setState({ visible })
  }

  _hide() {
    this._changeVisibility(false)

    if(this.props.onHide){
      this.props.onHide()
    }
  }

  render() {
    let {
      visible,
      title,
      message,
      value,
      error,
      placeholder,
      confirmText
     } = this.state
    return (
      <Modal
        show={visible}
        onHide={() => {
          if(this.props.onHide){
            this.props.onHide()
          }
        }}
      >

        <Modal.Body>
          <div className="kt-portlet kt-portlet--mobile">
    				<div className="kt-portlet__body">
              <h4 style={{ textAlign: 'center' }}>{ title }</h4>
    					<p style={{ textAlign: 'center' }}>{ message }</p>
    					<div className="row">
      					<div className="form-group col-md-12">
                  <img src={require("../../../../assets/gif/confirmation.gif")} width="100%"/>
                </div>
      				</div>
            </div>
    			</div>
        </Modal.Body>
      </Modal>
    )
  }
}
