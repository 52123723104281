import React from "react"

export default class QuickPanel extends React.Component {
  render() {
    return (
      <div id="kt_quick_panel" className="kt-quick-panel">
  			<a href="#" className="kt-quick-panel__close" id="kt_quick_panel_close_btn"><i className="flaticon2-delete"></i></a>
  			<div className="kt-quick-panel__nav">
  				<ul className="nav nav-tabs nav-tabs-line nav-tabs-bold nav-tabs-line-3x nav-tabs-line-brand  kt-notification-item-padding-x" role="tablist">
  					<li className="nav-item active">
  						<a className="nav-link active" data-toggle="tab" href="#kt_quick_panel_tab_notifications" role="tab">Notifications</a>
  					</li>
  					<li className="nav-item">
  						<a className="nav-link" data-toggle="tab" href="#kt_quick_panel_tab_settings" role="tab">Settings</a>
  					</li>
  				</ul>
  			</div>
  			<div className="kt-quick-panel__content">
  				<div className="tab-content">
  					<div className="tab-pane fade show kt-scroll active" id="kt_quick_panel_tab_notifications" role="tabpanel">
  						<div className="kt-notification">
  							<a href="#" className="kt-notification__item">
  								<div className="kt-notification__item-icon">
  									<i className="flaticon-truck kt-font-success"></i>
  								</div>
  								<div className="kt-notification__item-details">
  									<div className="kt-notification__item-title">
  										New call off has been received
  									</div>
  									<div className="kt-notification__item-time">
  										2 hrs ago
  									</div>
  								</div>
  							</a>
  							<a href="#" className="kt-notification__item">
  								<div className="kt-notification__item-icon">
  									<i className="flaticon-avatar kt-font-brand"></i>
  								</div>
  								<div className="kt-notification__item-details">
  									<div className="kt-notification__item-title">
  										New user is registered
  									</div>
  									<div className="kt-notification__item-time">
  										3 hrs ago
  									</div>
  								</div>
  							</a>
  							<a href="#" className="kt-notification__item">
  								<div className="kt-notification__item-icon">
  									<i className="flaticon-list-3 kt-font-danger"></i>
  								</div>
  								<div className="kt-notification__item-details">
  									<div className="kt-notification__item-title">
  										Services have been approved
  									</div>
  									<div className="kt-notification__item-time">
  										3 hrs ago
  									</div>
  								</div>
  							</a>
  							<a href="#" className="kt-notification__item">
  								<div className="kt-notification__item-icon">
  									<i className="flaticon2-box kt-font-warning"></i>
  								</div>
  								<div className="kt-notification__item-details">
  									<div className="kt-notification__item-title">
  										New dealership has been added
  									</div>
  									<div className="kt-notification__item-time">
  										5 hrs ago
  									</div>
  								</div>
  							</a>
  							<a href="#" className="kt-notification__item">
  								<div className="kt-notification__item-icon">
  									<i className="flaticon2-paperplane kt-font-info"></i>
  								</div>
  								<div className="kt-notification__item-details">
  									<div className="kt-notification__item-title">
  										New query has been made
  									</div>
  									<div className="kt-notification__item-time">
  										8 hrs ago
  									</div>
  								</div>
  							</a>
  							<a href="#" className="kt-notification__item">
  								<div className="kt-notification__item-icon">
  									<i className="flaticon2-calendar-1 kt-font-success"></i>
  								</div>
  								<div className="kt-notification__item-details">
  									<div className="kt-notification__item-title">
  										Your car is ready to collect
  									</div>
  									<div className="kt-notification__item-time">
  										12 hrs ago
  									</div>
  								</div>
  							</a>
  							<a href="#" className="kt-notification__item">
  								<div className="kt-notification__item-icon">
  									<i className="flaticon2-favourite kt-font-danger"></i>
  								</div>
  								<div className="kt-notification__item-details">
  									<div className="kt-notification__item-title">
  										Car delivered successfully
  									</div>
  									<div className="kt-notification__item-time">
  										15 hrs ago
  									</div>
  								</div>
  							</a>
  							<a href="#" className="kt-notification__item kt-notification__item--read">
  								<div className="kt-notification__item-icon">
  									<i className="flaticon-map-location kt-font-primary"></i>
  								</div>
  								<div className="kt-notification__item-details">
  									<div className="kt-notification__item-title">
  										<strong>VIN:12343222</strong> has changed status to <strong className="kt-font-primary">IN TRANSIT</strong>
  									</div>
  									<div className="kt-notification__item-time">
  										19 hrs ago
  									</div>
  								</div>
  							</a>
  						</div>
  					</div>
  					<div className="tab-pane kt-quick-panel__content-padding-x fade kt-scroll" id="kt_quick_panel_tab_settings" role="tabpanel">
  						<form className="kt-form">
  							<div className="kt-heading kt-heading--sm kt-heading--space-sm">Notification Settings</div>
  							<div className="form-group form-group-xs row">
  								<label className="col-8 col-form-label">Vehicles Change Status:</label>
  								<div className="col-4 kt-align-right">
  									<span className="kt-switch kt-switch--primary kt-switch--sm">
  										<label>
  											<input type="checkbox" defaultChecked name="quick_panel_notifications_1"/>
  											<span></span>
  										</label>
  									</span>
  								</div>
  							</div>
  							<div className="form-group form-group-xs row">
  								<label className="col-8 col-form-label">Vehicle Enters Transport:</label>
  								<div className="col-4 kt-align-right">
  									<span className="kt-switch kt-switch--primary kt-switch--sm">
  										<label>
  											<input type="checkbox" name="quick_panel_notifications_2"/>
  											<span></span>
  										</label>
  									</span>
  								</div>
  							</div>
  							<div className="form-group form-group-last form-group-xs row">
  								<label className="col-8 col-form-label">Vehicle Delivered:</label>
  								<div className="col-4 kt-align-right">
  									<span className="kt-switch kt-switch--primary kt-switch--sm">
  										<label>
  											<input type="checkbox" defaultChecked name="quick_panel_notifications_2"/>
  											<span></span>
  										</label>
  									</span>
  								</div>
  							</div>
  							<div className="form-group form-group-last form-group-xs row">
  								<label className="col-8 col-form-label">NVD News & Updates:</label>
  								<div className="col-4 kt-align-right">
  									<span className="kt-switch kt-switch--primary kt-switch--sm">
  										<label>
  											<input type="checkbox" defaultChecked name="quick_panel_notifications_2"/>
  											<span></span>
  										</label>
  									</span>
  								</div>
  							</div>
  						</form>
  					</div>
  				</div>
  			</div>
  		</div>
    )
  }
}
