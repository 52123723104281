import React from 'react'
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom'

import Settings from './Settings'
import Dealerships from './Dealerships'
import CallOffLimits from './CallOffLimits'

import LeftMenu from './components/LeftMenu'
import MobileHeader from './components/MobileHeader'
import Footer from './components/Footer'
import Header from './components/Header'
import SubHeader from './components/SubHeader'

export default class App extends React.Component {
  render() {
    return (
      <div>
        <MobileHeader />

        <div className="kt-grid kt-grid--hor kt-grid--root">
          <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">
            <LeftMenu />
            <div
              className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper"
              id="kt_wrapper"
            >
              <Header />
              <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
                <SubHeader />

                {/* begin:: Content */}
                <div
                  className="kt-content  kt-grid__item kt-grid__item--fluid"
                  id="kt_content"
                >
                  <Switch>
                    <Route exact path="/admin" component={Dealerships} />
                    <Route path="/admin/settings" component={Settings} />
                    <Route exsct path="/admin/call-off-limits" component={CallOffLimits} />
                    <Redirect to="/404" />
                  </Switch>

                  {/*  end:: Content  */}
                </div>
                {/*  begin:: Footer  */}
                <Footer />
                {/*  end:: Footer  */}
              </div>
            </div>
          </div>
          {/*  end:: Page  */}
          {/*  begin::Scrolltop  */}
          <div id="kt_scrolltop" className="kt-scrolltop">
            <i className="fa fa-arrow-up" />
          </div>
        </div>
      </div>
    )
  }
}
