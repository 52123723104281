import React from "react"
import { withRouter } from "react-router-dom";

import AuthManager from '../../../utils/AuthManager'

class SubHeader extends React.Component {
  constructor(props){
    super(props)

    this.state = {

    }
  }

  componentDidMount(){
    this._updateActive()
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this._updateActive();
    }
  }

  _goToAdmin(){
    this.props.history.push("/admin")
  }

  _updateActive() {
    switch(this.props.location.pathname){
      case "/dealer":
        this._setActive("Dashboard")
        break;

      case "/dealer/":
        this._setActive("Dashboard")
        break;

      case "/dealer/self-collect":
        this._setActive("Self Collect")
        break;

      case "/dealer/query-cars":
        this._setActive("Query Cars")
        break;

      case "/dealer/add-services":
        this._setActive("Add Services")
        break;

      case "/dealer/call-off":
        this._setActive("Call Off")
        break;

      case "/dealer/checkout":
        this._setActive("Checkout")
        break;

      case "/dealer/user":
        this._setActive("Users")
        break;

      case "/dealer/dealerships":
        this._setActive("Dealerships")
        break;

      default:
        // do nothing
        break;
    }
  }

  _setActive(title){
    this.setState({ title })
  }

  _renderAdminBanner(){

    if(!AuthManager.isSuperUser()){
      return
    }
    let title = this.state.title
    let text = "You are currently viewing the " + title + " page on behalf of dealer"
    if(AuthManager.getSuperUserSelectedDealerCodes().length <= 1){
      text += ": "+ AuthManager.getSuperUserSelectedDealerCodes()[0]
    }
    else{
      let dealersNo = AuthManager.getSuperUserSelectedDealerCodes().length
      let others = dealersNo - 1 === 1 ? "other" : "others"
      text += "s: "+ AuthManager.getSuperUserSelectedDealerCodes()[0] + " and " + (dealersNo - 1) + " " + others
    }

    return (
      <div className="kt-subheader kt-grid__item" id="kt_subheader">
        <div className="kt-subheader__main">
          <h5 className="kt-subheader__title">{ text }</h5>
        </div>
        <div className="kt-subheader__toolbar">
          <div className="kt-form__control">
            <div className="btn-toolbar">
              <div className="dropdown">
                <button
                  type="button"
                  className="btn btn-brand btn-sm"
                  onClick={() => this._goToAdmin()}
                >
                  Return to Admin
                </button>
              </div>
              &nbsp;&nbsp;&nbsp;
            </div>
          </div>
        </div>
      </div>
    )
  }

  render() {
    let { title } = this.state

    if(AuthManager.isSuperUser()){
      return this._renderAdminBanner()
    }

    return (
      <div className="kt-subheader kt-grid__item" id="kt_subheader">

        <div className="kt-subheader__main">
          <h3 className="kt-subheader__title">{ title }</h3>

        </div>
        <div className="kt-subheader__toolbar">

        </div>
      </div>
    )
  }
}

export default withRouter(SubHeader);
