import React from 'react'
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom'

import AuthenticatedRoute from '../dealer/components/AuthenticatedRoute'

import Login from './Login'
import ForgotPassword from './ForgotPassword'
import ResetPassword from './ResetPassword'
import Tracking from './Tracking'

export default class App extends React.Component {
  render() {
    return (
      <div
        className="kt-grid kt-grid--ver kt-grid--root"
        style={{
          flex: 1,
          display: 'flex',
          '-webkit-box-flex': 1
        }}
      >
        <Switch>
          <Route exact path="/user/login" component={Login} />
          <Route
            exact
            path="/user/forgot-password"
            component={ForgotPassword}
          />
          <Route
            exact
            path="/user/reset-password/:email"
            component={ResetPassword}
          />
          <Redirect to="/404" />
        </Switch>
      </div>
    )
  }
}
