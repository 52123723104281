import app from 'firebase/app' ;
import 'firebase/analytics';

const config = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
};

const firebase = app.initializeApp(config);

export default class DataCollection {

  static setUser(user){
    firebase.analytics().setUserId(`${user.id}`)
    firebase.analytics().setUserProperties({
      user_id: `${user.id}`,
      email: user.email,
      name: user.name
    });
  }

  static setDealership(dealership){
    firebase.analytics().setUserProperties({
      dealership_code: dealership.code,
      dealership_name: dealership.name
    });
  }

  static trackScreenOpened(screen){
    firebase.analytics().setCurrentScreen(screen, screen)
    DataCollection.track("Screen_"+screen)
  }

  static track(label, params={}){
    if(!process.env.NODE_ENV || process.env.NODE_ENV === 'development'){
      console.log("Data collection will be disabled for non production")
      return
    }

    try{
      firebase.analytics().logEvent(label, params)
    }
    catch(error){
      console.log("Error collecting data:", error)
    }
  }

}
