import React from 'react'
import { withRouter } from 'react-router-dom'

import AuthManager from '../../../utils/AuthManager'

class Header extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      username: AuthManager.currentUser.name
    }
  }
  _onSelfCollectPressed() {
    this._goTo('/dealer/self-collect')
  }

  _onAddServicesPressed() {
    this._goTo('/dealer/add-services')
  }

  _onQueryCarsPressed() {
    this._goTo('/dealer/query-cars')
  }

  _onCallOffPressed() {
    this._goTo('/dealer/call-off')
  }

  _onCallOffPressed() {
    this._goTo('/dealer/call-off')
  }

  _onDealershipsPressed() {
    this._goTo('/dealer/dealerships')
  }

  _onUsersPressed() {
    this._goTo('/dealer/users')
  }

  _goTo(url) {
    window.$('.dropdown-menu.dropdown-menu-fit').removeClass('show')
    this.props.history.push(url)
  }

  _onLogoutPressed() {
    AuthManager.logout()
    this._goTo('/user/login')
  }

  render() {
    let { username } = this.state
    let initial = username.charAt(0)

    return (
      <div
        id="kt_header"
        className="kt-header kt-grid__item  kt-header--fixed "
      >
        <button
          className="kt-header-menu-wrapper-close"
          id="kt_header_menu_mobile_close_btn"
        >
          <i className="la la-close" />
        </button>
        <div className="kt-header-menu-wrapper" id="kt_header_menu_wrapper">
          <div
            id="kt_header_menu"
            className="kt-header-menu kt-header-menu-mobile  kt-header-menu--layout-default "
          >
            <ul className="kt-menu__nav ">
              <li
                className="kt-menu__item kt-menu__item--submenu kt-menu__item--rel kt-menu__item--active"
                data-ktmenu-submenu-toggle="click"
                aria-haspopup="true"
              >
                {/*
                  <a href="javascript:;" className="kt-menu__link kt-menu__toggle" style={{cursor: 'default'}}>
                  <span className="kt-menu__link-text">
                     Nissan Ireland
                  </span>
                  <i className="kt-menu__ver-arrow la la-angle-right">
                  </i>
                </a>
                */}
                <div className="kt-menu__submenu kt-menu__submenu--classic kt-menu__submenu--left" />
              </li>
            </ul>
          </div>
        </div>

        {/*  end:: Header Menu */}

        {/*  begin:: Header Topbar */}
        <div className="kt-header__topbar">
          {/* begin: Search */}

          {/* end: Search */}

          {/* begin: Notifications */}

          {/* begin: Quick Actions */}
          <div className="kt-header__topbar-item dropdown">
            <div
              className="kt-header__topbar-wrapper"
              data-toggle="dropdown"
              data-offset="30px,0px"
              aria-expanded="true"
            >
              <span className="kt-header__topbar-icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  xmlnsXlink="http://www.w3.org/1999/xlink"
                  width="24px"
                  height="24px"
                  viewBox="0 0 24 24"
                  version="1.1"
                  className="kt-svg-icon"
                >
                  <g
                    stroke="none"
                    strokeWidth="1"
                    fill="none"
                    fillRule="evenodd"
                  >
                    <rect id="bound" x="0" y="0" width="24" height="24" />
                    <rect
                      id="Rectangle-62-Copy"
                      fill="#000000"
                      opacity="0.3"
                      x="13"
                      y="4"
                      width="3"
                      height="16"
                      rx="1.5"
                    />
                    <rect
                      id="Rectangle-62-Copy-2"
                      fill="#000000"
                      x="8"
                      y="9"
                      width="3"
                      height="11"
                      rx="1.5"
                    />
                    <rect
                      id="Rectangle-62-Copy-4"
                      fill="#000000"
                      x="18"
                      y="11"
                      width="3"
                      height="9"
                      rx="1.5"
                    />
                    <rect
                      id="Rectangle-62-Copy-3"
                      fill="#000000"
                      x="3"
                      y="13"
                      width="3"
                      height="7"
                      rx="1.5"
                    />
                  </g>
                </svg>{' '}
              </span>
            </div>
            <div className="dropdown-menu dropdown-menu-fit dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl">
              <form>
                {/* begin: Head */}
                <div className="kt-head kt-head--skin-light">
                  <h3 className="kt-head__title">
                    Quick Actions
                    <span className="kt-space-15" />
                  </h3>
                </div>

                {/* end: Head */}

                {/* begin: Grid Nav */}
                <div className="kt-grid-nav kt-grid-nav--skin-light">
                  <div className="kt-grid-nav__row">
                    <a
                      onClick={() => this._onCallOffPressed()}
                      className="kt-grid-nav__item"
                    >
                      <span className="kt-grid-nav__icon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlnsXlink="http://www.w3.org/1999/xlink"
                          width="24px"
                          height="24px"
                          viewBox="0 0 24 24"
                          version="1.1"
                          className="kt-svg-icon kt-svg-icon--primary kt-svg-icon--lg"
                        >
                          <g
                            stroke="none"
                            strokeWidth="1"
                            fill="none"
                            fillRule="evenodd"
                          >
                            <rect
                              id="bound"
                              x="0"
                              y="0"
                              width="24"
                              height="24"
                            />
                            <path
                              d="M8,4 C8.55228475,4 9,4.44771525 9,5 L9,17 L18,17 C18.5522847,17 19,17.4477153 19,18 C19,18.5522847 18.5522847,19 18,19 L9,19 C8.44771525,19 8,18.5522847 8,18 C7.44771525,18 7,17.5522847 7,17 L7,6 L5,6 C4.44771525,6 4,5.55228475 4,5 C4,4.44771525 4.44771525,4 5,4 L8,4 Z"
                              id="Combined-Shape"
                              fill="#000000"
                              opacity="0.3"
                            />
                            <rect
                              id="Rectangle-63-Copy"
                              fill="#000000"
                              opacity="0.3"
                              x="11"
                              y="7"
                              width="8"
                              height="8"
                              rx="4"
                            />
                            <circle
                              id="Oval"
                              fill="#000000"
                              cx="8"
                              cy="18"
                              r="3"
                            />
                          </g>
                        </svg>{' '}
                      </span>
                      <span className="kt-grid-nav__title">Call Off</span>
                      <span className="kt-grid-nav__desc">Order vehicles</span>
                    </a>
                    <a
                      onClick={() => this._onAddServicesPressed()}
                      className="kt-grid-nav__item"
                    >
                      <span className="kt-grid-nav__icon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlnsXlink="http://www.w3.org/1999/xlink"
                          width="24px"
                          height="24px"
                          viewBox="0 0 24 24"
                          version="1.1"
                          className="kt-svg-icon kt-svg-icon--primary kt-svg-icon--lg"
                        >
                          <g
                            stroke="none"
                            strokeWidth="1"
                            fill="none"
                            fillRule="evenodd"
                          >
                            <rect
                              id="bound"
                              x="0"
                              y="0"
                              width="24"
                              height="24"
                            />
                            <path
                              d="M15.9497475,3.80761184 L13.0246125,6.73274681 C12.2435639,7.51379539 12.2435639,8.78012535 13.0246125,9.56117394 L14.4388261,10.9753875 C15.2198746,11.7564361 16.4862046,11.7564361 17.2672532,10.9753875 L20.1923882,8.05025253 C20.7341101,10.0447871 20.2295941,12.2556873 18.674559,13.8107223 C16.8453326,15.6399488 14.1085592,16.0155296 11.8839934,14.9444337 L6.75735931,20.0710678 C5.97631073,20.8521164 4.70998077,20.8521164 3.92893219,20.0710678 C3.1478836,19.2900192 3.1478836,18.0236893 3.92893219,17.2426407 L9.05556629,12.1160066 C7.98447038,9.89144078 8.36005124,7.15466739 10.1892777,5.32544095 C11.7443127,3.77040588 13.9552129,3.26588995 15.9497475,3.80761184 Z"
                              id="Combined-Shape"
                              fill="#000000"
                            />
                            <path
                              d="M16.6568542,5.92893219 L18.0710678,7.34314575 C18.4615921,7.73367004 18.4615921,8.36683502 18.0710678,8.75735931 L16.6913928,10.1370344 C16.3008685,10.5275587 15.6677035,10.5275587 15.2771792,10.1370344 L13.8629656,8.7228208 C13.4724413,8.33229651 13.4724413,7.69913153 13.8629656,7.30860724 L15.2426407,5.92893219 C15.633165,5.5384079 16.26633,5.5384079 16.6568542,5.92893219 Z"
                              id="Rectangle-2"
                              fill="#000000"
                              opacity="0.3"
                            />
                          </g>
                        </svg>{' '}
                      </span>
                      <span className="kt-grid-nav__title">Add Services</span>
                      <span className="kt-grid-nav__desc">
                        Customise vehicles
                      </span>
                    </a>
                  </div>
                  <div className="kt-grid-nav__row">
                    <a
                      onClick={() => this._onQueryCarsPressed()}
                      className="kt-grid-nav__item"
                    >
                      <span className="kt-grid-nav__icon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlnsXlink="http://www.w3.org/1999/xlink"
                          width="24px"
                          height="24px"
                          viewBox="0 0 24 24"
                          version="1.1"
                          className="kt-svg-icon kt-svg-icon--primary kt-svg-icon--lg"
                        >
                          <g
                            stroke="none"
                            strokeWidth="1"
                            fill="none"
                            fillRule="evenodd"
                          >
                            <rect
                              id="bound"
                              x="0"
                              y="0"
                              width="24"
                              height="24"
                            />
                            <path
                              d="M4,16 L5,16 C5.55228475,16 6,16.4477153 6,17 C6,17.5522847 5.55228475,18 5,18 L4,18 C3.44771525,18 3,17.5522847 3,17 C3,16.4477153 3.44771525,16 4,16 Z M1,11 L5,11 C5.55228475,11 6,11.4477153 6,12 C6,12.5522847 5.55228475,13 5,13 L1,13 C0.44771525,13 6.76353751e-17,12.5522847 0,12 C-6.76353751e-17,11.4477153 0.44771525,11 1,11 Z M3,6 L5,6 C5.55228475,6 6,6.44771525 6,7 C6,7.55228475 5.55228475,8 5,8 L3,8 C2.44771525,8 2,7.55228475 2,7 C2,6.44771525 2.44771525,6 3,6 Z"
                              id="Combined-Shape"
                              fill="#000000"
                              opacity="0.3"
                            />
                            <path
                              d="M10,6 L22,6 C23.1045695,6 24,6.8954305 24,8 L24,16 C24,17.1045695 23.1045695,18 22,18 L10,18 C8.8954305,18 8,17.1045695 8,16 L8,8 C8,6.8954305 8.8954305,6 10,6 Z M21.0849395,8.0718316 L16,10.7185839 L10.9150605,8.0718316 C10.6132433,7.91473331 10.2368262,8.02389331 10.0743092,8.31564728 C9.91179228,8.60740125 10.0247174,8.9712679 10.3265346,9.12836619 L15.705737,11.9282847 C15.8894428,12.0239051 16.1105572,12.0239051 16.294263,11.9282847 L21.6734654,9.12836619 C21.9752826,8.9712679 22.0882077,8.60740125 21.9256908,8.31564728 C21.7631738,8.02389331 21.3867567,7.91473331 21.0849395,8.0718316 Z"
                              id="Combined-Shape"
                              fill="#000000"
                            />
                          </g>
                        </svg>{' '}
                      </span>
                      <span className="kt-grid-nav__title">Query Cars</span>
                      <span className="kt-grid-nav__desc">Query a vehicle</span>
                    </a>
                    {/*}
										<a onClick={() => this._onSelfCollectPressed()} className="kt-grid-nav__item">
											<span className="kt-grid-nav__icon">
												<svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" className="kt-svg-icon kt-svg-icon--primary kt-svg-icon--lg">
											    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
											        <rect id="bound" x="0" y="0" width="24" height="24"/>
											        <path d="M3,10.0500091 L3,8 C3,7.44771525 3.44771525,7 4,7 L9,7 L9,9 C9,9.55228475 9.44771525,10 10,10 C10.5522847,10 11,9.55228475 11,9 L11,7 L21,7 C21.5522847,7 22,7.44771525 22,8 L22,10.0500091 C20.8588798,10.2816442 20,11.290521 20,12.5 C20,13.709479 20.8588798,14.7183558 22,14.9499909 L22,17 C22,17.5522847 21.5522847,18 21,18 L11,18 L11,16 C11,15.4477153 10.5522847,15 10,15 C9.44771525,15 9,15.4477153 9,16 L9,18 L4,18 C3.44771525,18 3,17.5522847 3,17 L3,14.9499909 C4.14112016,14.7183558 5,13.709479 5,12.5 C5,11.290521 4.14112016,10.2816442 3,10.0500091 Z M10,11 C9.44771525,11 9,11.4477153 9,12 L9,13 C9,13.5522847 9.44771525,14 10,14 C10.5522847,14 11,13.5522847 11,13 L11,12 C11,11.4477153 10.5522847,11 10,11 Z" id="Combined-Shape-Copy" fill="#000000" opacity="0.3" transform="translate(12.500000, 12.500000) rotate(-45.000000) translate(-12.500000, -12.500000) "/>
											    </g>
											</svg> </span>
											<span className="kt-grid-nav__title">Collect a Car</span>
											<span className="kt-grid-nav__desc">Order for collection</span>
										</a>
                    */}
                  </div>
                </div>

                {/* end: Grid Nav */}
              </form>
            </div>
          </div>

          {/* end: Quick Actions */}

          {/* begin: User Bar */}
          <div className="kt-header__topbar-item kt-header__topbar-item--user">
            <div
              className="kt-header__topbar-wrapper"
              data-toggle="dropdown"
              data-offset="0px,0px"
            >
              <div className="kt-header__topbar-user">
                <span className="kt-header__topbar-welcome kt-hidden-mobile">
                  Hi,
                </span>
                <span className="kt-header__topbar-username kt-hidden-mobile">
                  {username}
                </span>

                {/* use below badge element instead the user avatar to display username's first letter(remove kt-hidden class to display it) */}
                <span className="kt-badge kt-badge--username kt-badge--unified-danger kt-badge--lg kt-badge--rounded kt-badge--bold">
                  {initial}
                </span>
              </div>
            </div>
            <div className="dropdown-menu dropdown-menu-fit dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl">
              {/* begin: Head */}
              <div className="kt-user-card kt-user-card--skin-light kt-notification-item-padding-x">
                <div className="kt-user-card__avatar">
                  {/* use below badge element instead the user avatar to display username's first letter(remove kt-hidden class to display it) */}
                  <span className="kt-badge kt-badge--lg kt-badge--rounded kt-badge--bold kt-font-danger">
                    {initial}
                  </span>
                </div>
                <div className="kt-user-card__name">{username}</div>
              </div>

              {/* end: Head */}

              {/* begin: Navigation */}
              <div className="kt-notification">
                { !AuthManager.isSuperUser() &&
                  <>
                    <a
                      onClick={() => this._onUsersPressed()}
                      className="kt-notification__item"
                    >
                      <div className="kt-notification__item-icon">
                        <i className="flaticon2-calendar-3 kt-font-warning" />
                      </div>
                      <div className="kt-notification__item-details">
                        <div className="kt-notification__item-title kt-font-bold">
                          My Users
                        </div>
                        <div className="kt-notification__item-time">
                          Manage users on your account
                        </div>
                      </div>
                    </a>
                    <a
                      onClick={() => this._onDealershipsPressed()}
                      className="kt-notification__item"
                    >
                      <div className="kt-notification__item-icon">
                        <i className="flaticon2-rocket-1 kt-font-danger" />
                      </div>
                      <div className="kt-notification__item-details">
                        <div className="kt-notification__item-title kt-font-bold">
                          My Dealerships
                        </div>
                        <div className="kt-notification__item-time">
                          Manage your dealerships
                        </div>
                      </div>
                    </a>
                  </>
                }
                <div className="kt-notification__custom">
                  <a
                    onClick={() => this._onLogoutPressed()}
                    target="_blank"
                    className="btn btn-label-brand btn-sm btn-bold"
                  >
                    Log Out
                  </a>
                </div>
              </div>

              {/* end: Navigation */}
            </div>
          </div>

          {/* end: User Bar */}
        </div>

        {/*  end:: Header Topbar */}
      </div>
    )
  }
}

export default withRouter(Header)
