import React from 'react'
import Modal from 'react-bootstrap/Modal'

export default class InputModal extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      show: props.show,
      title: props.title,
      subtitle: props.subtitle,
      confirmText: props.confirmText,
    }
  }

  componentWillReceiveProps(nextProps){
    this.setState(nextProps)
  }


  render() {
    let {
      show,
      title,
      subtitle,
      confirmText
     } = this.state

    return (
      <Modal
        show={show}
        onHide={() => this.props.onHide()}
      >
        <Modal.Header closeButton>
          <Modal.Title>{ title }</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="kt-portlet kt-portlet--mobile">
    				<div className="kt-portlet__body">
    					<p>{ subtitle }</p>
            </div>
    			</div>
        </Modal.Body>

        <Modal.Footer>
            <button
              type="button"
              className="btn btn-secondary"
              onClick={() => this.props.onHide()}>Cancel</button>
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => this.props.onConfirmPressed()}>{ confirmText }</button>
        </Modal.Footer>
      </Modal>
    )
  }
}
