import React from 'react'
import Modal from 'react-bootstrap/Modal'

export default class InputModal extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      show: props.show,
      title: props.title,
      subtitle: props.subtitle,
      placeholder: props.placeholder,
      confirmText: props.confirmText,
      value: props.value
    }
  }

  componentWillReceiveProps(nextProps){
    this.setState(nextProps)
  }

  _onConfirmPressed(){
    let { value } = this.state

    if(value == null || value == ""){
      this.setState({ error: "Please enter something" })
      return
    }

    this.props.onConfirmPressed(value)
  }

  render() {
    let {
      show,
      title,
      subtitle,
      value,
      error,
      placeholder,
      confirmText
     } = this.state
    return (
      <Modal
        show={show}
        onHide={() => this.props.onHide()}
      >
        <Modal.Header closeButton>
          <Modal.Title>{ title }</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="kt-portlet kt-portlet--mobile">
    				<div className="kt-portlet__body">
    					<p>{ subtitle }</p>
    					<div className="row">
      					<div className="form-group col-md-12">
                  <input
                    className="form-control"
                    type="text"
                    placeholder="Query"
                    value={value}
                    onChange={event => this.setState({ value: event.target.value, error: null }) }/>
      					</div>
                <div className="form-group col-md-12">
                  <p style={{color: 'red'}}>{ error }</p>
                </div>
      				</div>
            </div>
    			</div>
        </Modal.Body>

        <Modal.Footer>
            <button
              type="button"
              className="btn btn-secondary"
              onClick={() => this.props.onHide()}>Close</button>
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => this._onConfirmPressed()}>{ confirmText }</button>
        </Modal.Footer>
      </Modal>
    )
  }
}
