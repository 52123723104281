import React from 'react'

import DealerCodes from './components/tables/DealerCodes'
import SearchBar from './components/SearchBar'
import TextField from './components/TextField'

import InputModal from '../dealer/components/modals/InputModal'
import ConfirmModal from '../dealer/components/modals/ConfirmModal'

import AuthManager from '../../utils/AuthManager'
import Backend from '../../utils/Backend'

export default class Settings extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isLoading: false,
      searchTerm: "",
      dealerCodes: []
    }
    this._setSettings()
    this.table = React.createRef()
  }

  _setSettings() {
    Backend.getSettings()
      .then((data) => {
        this.setState({ data })
      })
      .catch((err) => {
        console.error('err', err)
      })
  }

  _isValid() {
    let { data } = this.state
    let isValid = true
    if (!this.refs.tfBuffer.isValid()) {
      isValid = false
    }
    return isValid
  }

  _onUpdatePressed() {
    if (!this._isValid()) {
      return
    }
    this._updateSettings()
  }

  _updateSettings() {
    let { data } = this.state
    Backend.updateSettings(data)
      .then((data) => {
        alert("Settings Successfully Updated")
      })
      .catch((err) => {
        console.error('err', err)
      })
  }

  _updateData(value, index) {
    let { data } = this.state
    data[index].value = value
    this.setState({ data })
  }

  _addDealerGarage(garage){
    Backend.addExcludedDealerGarage(garage)
    .then((data) => {

      this.setState({
        showDealerCodeModal: false
      }, () => alert("Added Dealer!"))

      this.table.current.refresh()
    })
    .catch((err) => {
      console.error('err', err)
    })
  }


  _deleteDealerGarages(){
    Backend.deleteExcludedDealerGarages(this.state.dealerCodes)
    .then(() => {

      this.setState({
        showDeleteDealerGaragesModal: false
      }, () => alert("Deleted Dealer(s)!"))

      this.table.current.refresh()
    })
    .catch((err) => {
      console.error('err', err)
    })
  }

  _renderActionButtons(){
    let { dealerCodes } = this.state

    return (
      <div className="kt-form kt-form--label-align-right kt-margin-t-20">
        <div className="row align-items-center">
          <div className="col-lg-12 text-right">
            { dealerCodes.length > 0 &&
              <button
                type="button"
                className="btn btn-brand-outline btn-sm"
                style={{ marginRight: 30}}
                onClick={() => this.setState({ showDeleteDealerGaragesModal: true })}
              >
                Delete Excluded Dealer Code(s)
              </button>
            }
            <button
              type="button"
              className="btn btn-brand btn-sm"
              onClick={() => this.setState({ showDealerCodeModal: true })}
            >
              Add Dealer Code
            </button>
          </div>
        </div>
      </div>
    )
  }

  render() {
    return (
      <>
        <div className="kt-portlet kt-portlet--tabs">
          <div className="kt-portlet__head kt-portlet__head--lg">
            <div className="kt-portlet__head-label">
              <span className="kt-portlet__head-icon">
                <i className="kt-font-brand flaticon-settings" />
              </span>
              <h3 className="kt-portlet__head-title">Settings</h3>
            </div>
          </div>
          <div className="kt-portlet__body">
            <div
              className="tab-pane"
              id="kt_apps_contacts_view_tab_5"
              role="tabpanel"
            >
              <form className="kt-form kt-form--label-left">
                <div className="kt-form__body">
                  <div className="kt-section">
                    <div className="kt-section__body">
                      <div className="row">
                        <div className="col-xl-12">
                          <div className="kt-section__body">
                            <div className="form-group row col-lg-12 kt-block-center">
                              <div className="form-group row">
                                <label className="col-xl-4 col-lg-4 col-form-label">
                                  ETA Buffer
                                </label>
                                <div className="col-lg-6 col-xl-6">
                                  <TextField
                                    ref="tfBuffer"
                                    value={
                                      this.state.data
                                        ? this.state.data[0].value
                                        : null
                                    }
                                    className="form-control"
                                    type="number"
                                    placeholder="Hours"
                                    onChangeText={(value) => {
                                      this._updateData(value, 0)
                                    }}
                                  />
                                </div>
                                <label className="col-xl-2 col-lg-2 col-form-label">
                                  hours
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="kt-separator kt-separator--space-lg kt-separator--fit kt-separator--border-solid" />
                <div className="kt-form__actions">
                  <div className="row">
                    <div className="col-lg-12 col-xl-12">
                      <button
                        style={{ color: 'white' }}
                        onClick={() => this._onUpdatePressed()}
                        disabled={this.state.isLoading}
                        type="button"
                        className="btn btn-brand btn-bold"
                      >
                        Save changes
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>

        <div className="kt-portlet mt-5">

          <div className="kt-portlet__head kt-portlet__head--lg">
            <div className="kt-portlet__head-label">
              <span className="kt-portlet__head-icon">
                <i className="kt-font-brand flaticon-settings" />
              </span>
              <h3 className="kt-portlet__head-title">ETA Buffer Excluded Dealer Codes</h3>
            </div>
          </div>

          <div className="kt-portlet__body">
            <SearchBar
               onSearchTermChanged={searchTerm => this.setState({ searchTerm })}
               showSelectStatusFilters={false}
            />
            {this._renderActionButtons() }
          </div>

          <div className="kt-portlet__body">
            {/* begin: Datatable  */}
            <DealerCodes
              ref={this.table}
              searchTerm={this.state.searchTerm}
              onSelectedDealerCodesUpdated={dealerCodes => this.setState({ dealerCodes })}
            />
          </div>

          <InputModal
            show={this.state.showDealerCodeModal}
            onHide={() => this.setState({ showDealerCodeModal: false })}
            onConfirmPressed={dealerCode => this._addDealerGarage(dealerCode)}
            confirmText="Exclude Dealer"
            title="Exclude Dealer"
            placeholder="Dealer Code"
            subtitle="Please enter the dealer code below"
          />

          <ConfirmModal
            show={this.state.showDeleteDealerGaragesModal}
            onHide={() => this.setState({ showDeleteDealerGaragesModal: false })}
            onConfirmPressed={() => this._deleteDealerGarages()}
            confirmText="Delete Dealer(s)"
            title="Delete Dealer(s)"
            subtitle="Are you sure? You cannot undo this action."
          />

        </div>
      </>
    )
  }
}
