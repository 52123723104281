import React from 'react'
import Modal from 'react-bootstrap/Modal'

import Spinner from 'react-bootstrap/Spinner'

import { Map, InfoWindow, Marker, GoogleApiWrapper } from 'google-maps-react'

import moment from 'moment'

import Backend from '../../../../utils/Backend'
import Orders from '../../../../utils/Orders'

import mapStyle from '../../../../assets/map/mapStyle.json'
const FINAL_MAP_MARKER = require('../../../../assets/map/garage.png')
const MAP_MARKER = require('../../../../assets/map/car.png')
const MAP_STYLE = { height: 310 }

export class TrackModal extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      order: props.order,
      show: props.show,
      loading: true,
      trackingDetails: null,
      // MAP
      center: {
        lat: 53.3167,
        lng: -6.236245,
      },
      zoom: 4,
      markers: [],
    }
    if(props.show){
      this._loadTrackingDetails(this.state.order)
    }
  }

  componentWillReceiveProps(nextProps) {
    let loadTrackingDetails = nextProps.order.vin != this.state.order.vin
    this.setState(nextProps, () => {
      if (loadTrackingDetails) {
        this._loadTrackingDetails(this.state.order)
      }
    })
  }

  _loadTrackingDetails(order) {
    this.setState({ trackingDetails: null, loading: true })

    Backend.track(order)
      .then((trackingDetails) => {
        let markers = [
          {
            lat: trackingDetails.gpsNorth,
            lng: trackingDetails.gpsEast,
          },
          {
            lat: trackingDetails.finalOrgGpsNorth,
            lng: trackingDetails.finalOrgGpsEast,
          },
        ]

        this.setState({
          trackingDetails,
          markers,
          loading: false,
        })
      })
      .catch((error) => {
        this.setState({ loading: false })
        alert("An unexpected error occurred, please contact support")
      })
  }

  _title(order) {
    return order.currentLocationCode + ' → ' + order.finalOrganization
  }

  _subTitle(order) {
    return order.make + ' ' + order.modelCode
  }

  _handleSharePressed(order) {
    this.setState({ showTrackModal: false, shareUrlLoading: true })

    Backend.getShareUrl(order).then((shareUrl) => {
      this.setState({ shareUrl, shareUrlLoading: false })
    })
  }

  _mapLoaded(mapProps, map) {
    this._setMapStyle(map)
    let { markers } = this.state
    if (markers.length == 0) {
      setTimeout(() => {
        this._mapLoaded(mapProps, map)
      }, 500)
      return
    }
    const bounds = this._getMapBounds(mapProps, map, markers)
    // TO:DO aniamted this
    map.fitBounds(bounds, true)
  }

  _setMapStyle(map) {
    map.setOptions({
      styles: mapStyle,
    })
  }

  _getMapBounds(mapProps, map, markers) {
    const { google } = this.props
    const bounds = new google.maps.LatLngBounds()

    markers.forEach((marker) => {
      bounds.extend(new google.maps.LatLng(marker.lat, marker.lng))
    })
    this._getDirections(map)
    return bounds
  }

  _getDirections(map) {
    const { google } = this.props
    let { markers } = this.state
    let { trackingDetails } = this.state
    // directionsService requires floats not strings
    markers = markers.map((marker) =>
      Object.entries(marker).reduce(
        (obj, [key, value]) => ((obj[key] = parseFloat(value)), obj),
        {}
      )
    )
    const directionsService = new google.maps.DirectionsService()
    const directionsDisplay = new google.maps.DirectionsRenderer()
    directionsDisplay.setOptions({
      suppressMarkers: true,
    })
    var request = {
      origin: markers[0],
      destination: markers[1],
      travelMode: 'DRIVING',
    }
    directionsService.route(request, function (result, status) {
      if (status === 'OK') {
        directionsDisplay.setMap(map)
        directionsDisplay.setDirections(result)
      } else {
        window.alert('Directions request failed due to ' + status)
      }
    })
  }

  _renderMarkers(trackingDetails, google) {
    if (!trackingDetails) {
      return null
    }

    let markers = [
      {
        lat: trackingDetails.gpsNorth,
        lng: trackingDetails.gpsEast,
        icon: MAP_MARKER,
      },
      {
        lat: trackingDetails.finalOrgGpsNorth,
        lng: trackingDetails.finalOrgGpsEast,
        icon: FINAL_MAP_MARKER,
      },
    ]

    return markers.map((marker) => {
      return (
        <Marker
          position={{
            lat: marker.lat,
            lng: marker.lng,
          }}
          icon={{
            url: marker.icon,
            anchor: new google.maps.Point(12, 12),
            scaledSize: new google.maps.Size(32, 32),
          }}
        />
      )
    })
  }

  _renderContent() {
    let { trackingDetails } = this.state
    const { google } = this.props
    return (
      <div>
        <div
          style={{
            width: '100%',
            height: 300,
            backgroundColor: 'lightgrey',
          }}
        >
          <Map
            style={MAP_STYLE}
            google={this.props.google}
            zoom={this.state.zoom}
            initialCenter={this.state.center}
            streetViewControl={false}
            mapTypeControl={false}
            onReady={(mapProps, map) => this._mapLoaded(mapProps, map)}
          >
            {this._renderMarkers(trackingDetails, google)}
          </Map>
        </div>
        {this._renderOrderInfo()}
      </div>
    )
  }

  _renderShareUrl() {
    let { shareUrl, shareUrlLoading } = this.state

    if (shareUrlLoading) {
      return (
        <div
          style={{
            textAlign: 'center',
            paddingTop: 10,
            paddingBottom: 10,
          }}
        >
          <Spinner animation="border" />
        </div>
      )
    }

    if (shareUrl) {
      return (
        <div
          style={{
            textAlign: 'center',
            paddingTop: 10,
            paddingBottom: 20,
          }}
        >
          <h5>Tracking Link:</h5>
          <p style={{ wordBreak: 'break-all' }}>{shareUrl}</p>
        </div>
      )
    }
  }
  _renderOrderInfo() {
    let { loading, trackingDetails, order } = this.state
    if (loading) {
      return (
        <div
          style={{
            textAlign: 'center',
            padding: 40,
          }}
        >
          <Spinner animation="border" />
        </div>
      )
    }

    let status = Orders.getFormattedStatus(order)

    let targetDate = '-'
    let actualDate = '-'
    if (trackingDetails) {
      if (trackingDetails.targetDate && trackingDetails.targetDate != '') {
        targetDate = moment(trackingDetails.targetDate).format('DD/MM/YYYY')
      }

      if (trackingDetails.actualDate && trackingDetails.actualDate != '') {
        actualDate = moment(trackingDetails.actualDate).format('DD/MM/YYYY')
      }
    }

    return (
      <div>
        <Modal.Body>
          <div className="kt-portlet kt-portlet--mobile">
            <div className="kt-portlet__body">
              <h4>
                {this._subTitle(order)} <small> {order.vin}</small>
              </h4>
              <span>
                <span className="kt-badge kt-badge--brand kt-badge--dot" />
                &nbsp;
                <span className="kt-font-bold" style={{ color: order.hexCode }}>
                  {' '}
                  {status}
                </span>
              </span>
              <br />
              <br />
              <div className="row">
                <div className="form-group col-md-4">
                  <label>Chassis / Comm:</label>
                  <p className="form-control-static">{order.chassis}</p>
                </div>
                <div className="form-group col-md-4">
                  <label>SLA ETA:</label>
                  <p className="form-control-static">{targetDate}</p>
                </div>
              </div>
              {this._renderShareUrl()}
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <button
            style={{ zIndex: 1 }}
            type="button"
            className="btn btn-secondary"
            onClick={() => this.props.onHide()}
          >
            Close
          </button>
          <button
            style={{ zIndex: 1 }}
            type="button"
            className="btn btn-secondary"
            onClick={() => this.props.onQueryCarPressed(order)}
          >
            Query Car
          </button>
          <button
            style={{ zIndex: 1 }}
            type="button"
            className="btn btn-primary"
            onClick={() => this._handleSharePressed(order)}
          >
            Share Tracking Link
          </button>
        </Modal.Footer>
      </div>
    )
  }

  render() {
    let { order } = this.state
    return (
      <Modal show={this.state.show} onHide={() => this.props.onHide()}>
        <Modal.Header closeButton>
          <Modal.Title>{this._title(order)}</Modal.Title>
        </Modal.Header>

        {this._renderContent()}
      </Modal>
    )
  }
}

export default GoogleApiWrapper({
  apiKey: process.env.REACT_APP_GMAPS_KEY,
  styles: mapStyle,
})(TrackModal)
