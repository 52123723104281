import moment from 'moment'

export default class Orders {
  static search(searchTerm, selectedFilters, orders){

    searchTerm = searchTerm ? searchTerm.toLowerCase() : ''

    let filterByLastMonth = false
    let filterByLast10Days = false


    selectedFilters.forEach(selectedFilter => {
      if(selectedFilter.id != 7){
        return
      }
      selectedFilter.subStatus.forEach(subfilter => {
        if(subfilter.id == 1 && subfilter.is_selected){
          filterByLast10Days = true
        }
        if(subfilter.id == 2 && subfilter.is_selected){
          filterByLastMonth = true
        }
      })
    })

    return orders.filter(order => {
      let vin = order.vin.toLowerCase()
      let comms = order.chassis ? order.chassis.toLowerCase() : "comms"

      let status = order.status ? order.status : ''
      let deliveredDate = order.deliveredDate ? order.deliveredDate : null

      let searchTermMatch = true
      let statusMatch = true
      let deliveredDateInRange = true


      if(searchTerm != null && searchTerm != ''){
        let searchTerms = searchTerm.split('\n')

        for(var i = 0; i < searchTerms.length; i++){
            var term = searchTerms[i]
            if(term == ""){
              continue
            }
            searchTermMatch = vin.indexOf(term) > -1 ||
                              status.indexOf(term) > -1 ||
                              comms.indexOf(term) > -1

            if(searchTermMatch){
              break;
            }
          }
      }

      if(selectedFilters.length > 0){
        statusMatch = selectedFilters.find(filter => {
          return status === filter.name
        }) != undefined
      }

      if((filterByLastMonth || filterByLast10Days) && deliveredDate == null){
        deliveredDateInRange = false
      }
      else if(filterByLastMonth){
        let monthAgo = moment().subtract(30, 'days')
        deliveredDateInRange = moment(deliveredDate).isAfter(monthAgo)
      }
      else if(filterByLast10Days){
        let tenDaysAgo = moment().subtract(10, 'days')
        deliveredDateInRange = moment(deliveredDate).isAfter(tenDaysAgo)
      }

      return searchTermMatch == true && statusMatch == true && deliveredDateInRange == true
    })
  }

  static getFormattedStatus(order){
    return order.status ? Orders.formattedStatus(order.status) : ''
  }

  static formattedStatus(status){
    return status.replace(/_/g, ' ')
  }

  static getVins(orders){
    return orders.map(order => {
      return order.vin
    })
  }
}
